const OrderSkeleton = () => {
	const renderSkeletons = () => {
		const skeletons = [];
		for (let i = 0; i < 1; i++) {
			skeletons.push(
				<div key={i} className='mb-4'>
					<div className='loading-bar h-4 w-2/5 rounded mb-2'></div>
					<div className='loading-bar h-4 rounded mb-2'></div>
					<div className='loading-bar h-4 rounded mb-2'></div>
					<div className='loading-bar h-4 w-3/5 rounded mb-4'></div>
					<div className='loading-bar h-4 w-4/5 rounded mb-2'></div>
					<div className='loading-bar h-4 rounded mb-2'></div>
					<div className='loading-bar h-4 rounded mb-2'></div>
					<div className='loading-bar h-4 w-2/5 rounded mb-2'></div>
					<div className='loading-bar h-4 rounded mb-2'></div>
					<div className='loading-bar h-4 w-3/5 rounded mb-2'></div>
				</div>
			);
		}
		return skeletons;
	};

	return <div>{renderSkeletons()}</div>;
};

export default OrderSkeleton;
