import { useEffect, useRef, useState } from "react";
import { CloseIcon, ImageIcon, PaperPlaneIcon, TrashIcon } from "../Icons";
import { useTranslation } from "react-i18next";
import useAuth from "../../hooks/useAuth";
import { useJwt } from "react-jwt";
import ImageModal from "../camera/ImageModal";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const DiaryModal = ({
	onClose,
	diaryData,
	wo,
	diaryRefId,
	diaryRefWrId,
	handleLoadDiary,
}) => {
	const { t } = useTranslation();
	const modalRef = useRef();

	const { auth } = useAuth();
	const { decodedToken } = useJwt(auth?.accessToken);

	const closeModal = () => {
		onClose();
	};

	return (
		<div className='p-2 fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-30'>
			<div
				ref={modalRef}
				className='rounded bg-white w-full h-full mx-auto p-4 pt-5 flex flex-col'
			>
				<div>
					<button
						className='btn btn-sm btn-circle btn-ghost text-black absolute top-3 right-3 shadow-inner border-gray-200'
						onClick={() => closeModal()}
					>
						<CloseIcon />
					</button>
					<p className='text-2xl text-blue-500 font-bold mb-2'>
						{t("diary")}
					</p>
				</div>
				<div className='flex-1 overflow-y-auto space-y-2 flex flex-col-reverse'>
					{diaryData.length > 0 &&
						diaryData.map((diary) => {
							return (
								<TextRow
									key={crypto.randomUUID()}
									diary={diary}
									decodedToken={decodedToken}
								/>
							);
						})}
					<p className='system-message'>{t("diaryStart")}</p>
				</div>
				<Chat
					decodedToken={decodedToken}
					wo={wo}
					diaryRefId={diaryRefId}
					diaryRefWrId={diaryRefWrId}
					handleLoadDiary={handleLoadDiary}
				/>
			</div>
		</div>
	);
};

const TextRow = ({ diary, decodedToken }) => {
	const fromMe = diary.created_by == decodedToken?.user?.id;
	const [selectedImageIndex, setSelectedImageIndex] = useState(null);

	const handleImageClick = (index) => {
		setSelectedImageIndex(index);
	};

	const formattedText = diary.text.replace(/\n/g, "<br>");
	const clickableText = formattedText.replace(
		/(?<!href=")(https?:\/\/[^\s]+)/g,
		'<a href="$1" class="!underline" target="_blank">$1</a>'
	);

	// const profilePictureUrl = base_url(`file/192/192/a/${profilePicture}`);
	return (
		<div className='w-full flex mt-2'>
			<div
				timesent={diary.created_at}
				className={`${fromMe ? "my-message" : "their-message"}`}
			>
				{/* <div className='flex flex-col items-center mr-2'>
					<div className='min-w-max'>
						<img
							className='mt-1 rounded-full inline-block h-9 w-9 sm:h-12 sm:w-12 mx-1 object-cover'
							src={profilePictureUrl}
							alt=''
						/>
					</div>
				</div> */}
				<div className='flex-rol'>
					{diary.reference_id > 0 && (
						<ReplyDiv
							key={crypto.randomUUID()}
							ref_id={diary.reference_id}
						/>
					)}
					<div
						className='message-container !text-left'
						style={{
							borderTopLeftRadius:
								diary.reference_id > 0 ? "0px" : "",
							borderTopRightRadius:
								diary.reference_id > 0 ? "0px" : "",
						}}
					>
						<div className='flex justify-between text-gray-400'>
							<div className='inline-block'>
								{new Date(
									diary.created_at * 1000
								).toLocaleString()}
							</div>
							<div className='inline-block ml-2'>
								{diary.created_by_name}
							</div>
						</div>
						<span
							dangerouslySetInnerHTML={{ __html: clickableText }}
						/>
						{diary.links && (
							<div className='filesPreview bg-gray-100 rounded-lg p-1 pt-2'>
								{diary.links.map((link, index) => {
									return (
										<div
											key={index}
											className='display inline-block mx-2'
											onClick={() =>
												handleImageClick(index)
											}
										>
											<img
												src={link}
												className='object-fit inline-block m-2'
											/>
										</div>
									);
								})}
							</div>
						)}
						{selectedImageIndex !== null && (
							<ImageModal
								allImages={diary?.links_orig ?? []}
								selectedImage={selectedImageIndex}
								onClose={() => setSelectedImageIndex(null)}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

const ReplyDiv = ({ ref_id }) => {
	const { t } = useTranslation();
	const val = parseInt(
		document
			?.querySelector(`div[data-line_id='${ref_id}']`)
			?.getAttribute("data-rowi")
	);
	return (
		<div
			data-reference_line_id={ref_id}
			className='messageResponse bg-clienty-100 w-full rounded-t-lg px-3 py-1'
		>
			{val > 0 ? (
				`${t("replyToTask")} ${val}`
			) : (
				<span className='line-through'>{t("replyDeleted")}</span>
			)}
		</div>
	);
};

const Chat = ({ decodedToken, wo, diaryRefId, diaryRefWrId, handleLoadDiary }) => {
	const axiosPrivate = useAxiosPrivate();
	const { t } = useTranslation();

	const [message, setMessage] = useState("");
	const [isReplying, setIsReplying] = useState(parseInt(diaryRefId) > 0);
	const [replyTaskNo, setReplyTaskNo] = useState(diaryRefId);

	const [capturedImagesData, setCapturedImagesData] = useState([]);
	const [capturedImagesUrl, setCapturedImagesUrl] = useState([]);

	const [selectedImageIndex, setSelectedImageIndex] = useState(null);

	const handleImageClick = (index) => {
		setSelectedImageIndex(index);
	};

	const handleFileUpload = (e) => {
		const files = e.target.files;
		for (let i = 0; i < files.length; i++) {
			const file = files[i];
			const reader = new FileReader();

			reader.onloadend = function (e) {
				let src = "";
				let name = file.name;
				switch (file.type) {
					case "image/png":
					case "image/jpeg":
					case "image/jpg":
						src = e.target.result;
						break;
					case "application/pdf":
						src = "/public/assets/img/pdf.png";
						break;
					case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
						src = "/public/assets/img/word.png";
						break;
					default:
						src = "/public/assets/img/unsupported.png";
						break;
				}
				const blob = new Blob([file], {
					type: file.type,
					name: name,
				});
				setCapturedImagesData((prevData) => [blob, ...prevData]);
				setCapturedImagesUrl((prevData) => [src, ...prevData]);
			};

			reader.readAsDataURL(file);
		}
	};

	const user_id = decodedToken?.user?.id ?? 0;

	const saveMessage = async () => {
		if (message == "") return;

		const formData = new FormData();

		capturedImagesData.forEach((blob, index) => {
			formData.append(
				"image" + index,
				blob,
				`${blob.name}-captured-image-${index}.${
					blob.type.split("/")[1]
				}`
			);
		});
		try {
			await axiosPrivate.post(
				`saveDiaryLine?text=${message}&created_by=${user_id}&wo_id=${wo.id}&ref_line_id=${diaryRefWrId}`,
				formData
			);
			handleLoadDiary();
		} catch (error) {
			console.error("Error:", error);
		}
		setMessage("");
	};

	const handleRemoveReply = () => {
		setIsReplying(false);
		setReplyTaskNo(0);
	};

	const handleDeleteImage = (imageIndex, e) => {
		e.stopPropagation();
		const updatedImagesData = capturedImagesData.filter(
			(_, index) => index !== imageIndex
		);
		setCapturedImagesData(updatedImagesData);
		const updatedImagesUrl = capturedImagesUrl.filter(
			(_, index) => index !== imageIndex
		);
		setCapturedImagesUrl(updatedImagesUrl);
	};

	return (
		<div id='client-chat' className='flex flex-col mt-0.5'>
			<div
				id='preview-container'
				className='flex overflow-x-auto w-full my-2'
			>
				{capturedImagesUrl.map((photo, index) => (
					<div
						key={index}
						className='flex-none p-1 w-1/2 sm:w-1/3 md:w-1/5 lg:w-1/6 xl:w-1/8 h-32'
						onClick={() => handleImageClick(index)}
					>
						<img
							src={photo}
							alt={`Captured ${index}`}
							className='w-full h-full object-contain'
						/>
						<div
							className='relative text-right'
							style={{ top: "-128px" }}
						>
							<button
								type='button'
								onClick={(e) => handleDeleteImage(index, e)}
								className='bg-red-500 hover:bg-red-700 text-white font-bold me-1 mt-1 p-1 rounded'
							>
								<TrashIcon />
							</button>
						</div>
					</div>
				))}
			</div>
			{selectedImageIndex !== null && (
				<ImageModal
					allImages={capturedImagesUrl}
					selectedImage={selectedImageIndex}
					onClose={() => setSelectedImageIndex(null)}
				/>
			)}
			{isReplying && (
				<div
					id='reference_line'
					className='bg-clienty-100 w-full rounded-md mb-1 px-3 py-1 flex'
				>
					{`${t("replyToTask")} ${replyTaskNo}`}
					<i
						className='ml-3 text-red-600'
						onClick={handleRemoveReply}
					>
						<CloseIcon />
					</i>
				</div>
			)}
			<div className='flex gap-2 h-14'>
				<label
					htmlFor='file-upload'
					className='btn btn-secondary h-full'
				>
					<ImageIcon />
				</label>
				<input
					id='file-upload'
					type='file'
					className='hidden'
					multiple
					onChange={handleFileUpload}
				/>
				<span id='messageInput-wrap' className='w-full'>
					<textarea
						id='messageInput'
						className='w-full'
						type='text'
						name='message'
						placeholder={t("typeMessage")}
						value={message}
						onChange={(e) => setMessage(e.target.value)}
					/>
				</span>
				<button
					id='client-send'
					onClick={saveMessage}
					type='submit'
					className='btn btn-primary h-full'
				>
					<PaperPlaneIcon />
				</button>
			</div>
		</div>
	);
};

export default DiaryModal;
