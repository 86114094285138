import { useTranslation } from "react-i18next";
import Header from "../components/layout/Header";
import { useNavigate } from "react-router-dom";

const Home = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const handleRedirect = (page) => {
		navigate("/" + page);
	};

	return (
		<div className=''>
			<Header homepage={true} />
			<div className='mt-5 mx-3'>
				<div
					className='bg-white border border-blue-500 hover:bg-gray-100 text-gray-500 hover:text-gray-700 font-bold rounded-lg p-4 mb-3 cursor-pointer shadow-md'
					onClick={() => handleRedirect("instalation")}
				>
					<div>
						<h1 className='text-xl font-bold text-blue-500'>
							{t("instalation")}
						</h1>
						<p className='text-sm text-gray-500'>
							{t("instalationDesc")}
						</p>
					</div>
				</div>
				<div
					className='bg-white border border-blue-500 hover:bg-gray-100 text-gray-500 hover:text-gray-700 font-bold rounded-lg p-4 mb-3 cursor-pointer shadow-md'
					onClick={() => handleRedirect("knowledge-base")}
				>
					<div>
						<h1 className='text-xl font-bold text-blue-500'>
							{t("knowledgeBase")}
						</h1>
						<p className='text-sm text-gray-500'>
							{t("knowledgeBaseDesc")}
						</p>
					</div>
				</div>
				<div
					className='bg-white border border-blue-500 hover:bg-gray-100 text-gray-500 hover:text-gray-700 font-bold rounded-lg p-4 mb-3 cursor-pointer shadow-md'
					onClick={() => handleRedirect("device-reassign")}
				>
					<div>
						<h1 className='text-xl font-bold text-blue-500'>
							{t("deviceReassign")}
						</h1>
						<p className='text-sm text-gray-500'>
							{t("deviceReassignDesc")}
						</p>
					</div>
				</div>
				<div
					className='bg-white border border-blue-500 hover:bg-gray-100 text-gray-500 hover:text-gray-700 font-bold rounded-lg p-4 mb-3 cursor-pointer shadow-md'
					onClick={() => handleRedirect("work-order")}
				>
					<div>
						<h1 className='text-xl font-bold text-blue-500'>
							{t("workOrder")}
						</h1>
						<p className='text-sm text-gray-500'>
							{t("workOrderDesc")}
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};
export default Home;
