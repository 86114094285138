import { useEffect, useRef, useState } from "react";
import { CloseIcon } from "../Icons";
import { useTranslation } from "react-i18next";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import TaskTypeSelect from "../selects/TaskTypeSelect";
import DeviceSelectTabs from "../selects/DeviceSelectTabs";

const NewWorkRowModal = ({ onClose, onSave, wo }) => {
	const { t } = useTranslation();
	const modalRef = useRef();
	const axiosPrivate = useAxiosPrivate();

	const [taskTypes, setTaskTypes] = useState([]);

	const [taskType, setTaskType] = useState(-1);
	const [device1, setDevice1] = useState(-1);
	const [device2, setDevice2] = useState(-1);
	const [device1Tab, setDevice1Tab] = useState(1);
	const [device2Tab, setDevice2Tab] = useState(1);
	const [note, setNote] = useState("");

	const [exchangePart, setExchangePart] = useState(false);

	const closeModal = () => {
		onClose();
	};

	const handleGetTypes = async () => {
		try {
			const response = await axiosPrivate.post(`getWorkTypes`);
			const data = response?.data?.data;
			setTaskTypes(data);
		} catch (error) {
			console.error("Error:", error);
		}
	};

	const handleSave = async () => {
		try {
			await axiosPrivate.post(
				`saveNewWorkRow?wo_id=${wo.id}&task_type=${taskType}&device_1=${device1}&device_2=${device2}&note=${note}&device_1_type=${device1Tab}&device_2_type=${device2Tab}`
			);
			onSave();
		} catch (error) {
			console.error("Error:", error);
		}
	};

	useEffect(() => {
		if (taskType == 2) {
			setExchangePart(true);
		} else {
			setExchangePart(false);
		}
	}, [taskType]);

	useEffect(() => {
		handleGetTypes();
	}, []);

	return (
		<div className='p-2 fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-30'>
			<div
				ref={modalRef}
				className='rounded bg-white w-full h-full mx-auto p-4 pt-5 flex flex-col'
			>
				<div className='first-part mb-2'>
					<button
						className='btn btn-sm btn-circle btn-ghost text-black absolute top-3 right-3 shadow-inner border-gray-200'
						onClick={() => closeModal()}
					>
						<CloseIcon />
					</button>
					<p className='text-2xl text-blue-500 font-bold mb-2'>
						{t("newOrderLine")}
					</p>
				</div>
				<div className='scrollable-part flex-1 overflow-y-auto'>
					<div className='mb-2'>
						<TaskTypeSelect
							onSelect={(type) => {
								setTaskType(type);
							}}
							required={true}
							taskTypeArr={taskTypes}
							labelClass={
								"block text-gray-700 text-sm font-bold mb-1"
							}
						/>
					</div>
					<div className='mb-2'>
						<DeviceSelectTabs
							selectedCustomer={wo.customer_id}
							onSelect={(dev) => setDevice1(dev)}
							onSelectTab={(idx) => setDevice1Tab(idx)}
							keepValue={false}
							required={true}
							label={exchangePart ? "device1" : "device"}
							useCodeAsValue={true}
							labelClass={
								"block text-gray-700 text-sm font-bold mb-1"
							}
						/>
					</div>
					{exchangePart && (
						<div className='mb-2'>
							<DeviceSelectTabs
								selectedCustomer={wo.customer_id}
								onSelect={(dev) => setDevice2(dev)}
								onSelectTab={(idx) => setDevice2Tab(idx)}
								keepValue={false}
								required={true}
								label="device2"
								useCodeAsValue={true}
								labelClass={
									"block text-gray-700 text-sm font-bold mb-1"
								}
							/>
						</div>
					)}
					<div className='mb-2'>
						<label className='block text-gray-700 text-sm font-bold mb-1'>
							{t("note")}
						</label>
						<textarea
							className='border border-gray-300 px-4 py-2 rounded w-full'
							value={note}
							onChange={(e) => setNote(e.target.value)}
						/>
					</div>
					<div className='flex justify-end'>
						<button
							className='float-right bg-white border border-green-500 hover:bg-green-100 text-green-500 hover:text-green-700 text-center font-bold py-2 px-4 rounded'
							onClick={handleSave}
						>
							{t("save")}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default NewWorkRowModal;
