import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TrashIcon } from "../Icons";
import DeleteImage from "./DeleteImage";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import ImageModal from "./ImageModal";

const CapturedPhotos = ({
	capturedImagesData,
	setCapturedImagesData,
	capturedImagesUrl,
	setCapturedImagesUrl,
	setExistPhoto,
	setNeedPhoto,
	setNewPhotoAdded,
	handleSetAlert,
}) => {
	const axiosPrivate = useAxiosPrivate();
	const { t } = useTranslation();
	const [deleteImageIndex, setDeleteImageIndex] = useState(null);

	const [selectedImageIndex, setSelectedImageIndex] = useState(null);

	const handleImageClick = (index) => {
		setSelectedImageIndex(index);
	};

	const handleDeleteImage = (imageIndex, e) => {
		e.stopPropagation();
		if (!capturedImagesData[imageIndex]) {
			setDeleteImageIndex(imageIndex);
		} else {
			handleConfirmDelete(imageIndex);
		}
	};

	const handleConfirmDelete = async (imageIndex) => {
		const parts = capturedImagesUrl[imageIndex].split("/");
		const filename = parts[parts.length - 1];
		try {
			const response = await axiosPrivate.post(
				`deleteAttachment/` + filename
			);
			if (response.data.status === 201) {
				const updatedImagesData = capturedImagesData.filter(
					(_, index) => index !== imageIndex
				);
				setCapturedImagesData(updatedImagesData);
				const updatedImagesUrl = capturedImagesUrl.filter(
					(_, index) => index !== imageIndex
				);
				setCapturedImagesUrl(updatedImagesUrl);
				setDeleteImageIndex(null);
				if (updatedImagesUrl.length === 0) {
					setExistPhoto(false);
					setNeedPhoto(true);
				}
				if (updatedImagesData.length === 0) {
					setNewPhotoAdded(false);
				}
				handleSetAlert(t("alertSuccessDelete"), "success");
			}
		} catch (error) {
			console.error("Error:", error);
			handleSetAlert(t("alertErrorDelete"), "error");
		}
	};

	const handleCancelDelete = () => {
		setDeleteImageIndex(null);
	};

	return (
		<>
			<div>
				<h3>{t("capturedPhoto")}</h3>
				<div className='flex overflow-x-auto w-full my-2'>
					{capturedImagesUrl.map((photo, index) => (
						<div
							key={index}
							className='flex-none p-1 w-1/2 sm:w-1/3 md:w-1/5 lg:w-1/6 xl:w-1/8 h-32'
							onClick={() => handleImageClick(index)}
						>
							<img
								src={photo}
								alt={`Captured ${index}`}
								className='w-full h-full object-contain'
							/>
							<div
								className='relative text-right'
								style={{ top: "-128px" }}
							>
								<button
									type='button'
									onClick={(e) => handleDeleteImage(index, e)}
									className='bg-red-500 hover:bg-red-700 text-white font-bold me-1 mt-1 p-1 rounded'
								>
									<TrashIcon />
								</button>
							</div>
						</div>
					))}
				</div>
				{selectedImageIndex !== null && (
					<ImageModal
						allImages={capturedImagesUrl}
						selectedImage={selectedImageIndex}
						onClose={() => setSelectedImageIndex(null)}
					/>
				)}
			</div>
			{deleteImageIndex !== null && (
				<DeleteImage
					onConfirm={() => handleConfirmDelete(deleteImageIndex)}
					onCancel={handleCancelDelete}
				/>
			)}
		</>
	);
};

export default CapturedPhotos;
