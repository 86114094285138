import { useEffect, useState } from "react";
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from "../Icons";

const TimePicker = ({ onSave, dateTime }) => {
	const [selectedHour, setSelectedHour] = useState(dateTime.getHours());
	const [selectedMinute, setSelectedMinute] = useState(dateTime.getMinutes());
	const [selectedSecond, setSelectedSecond] = useState(dateTime.getSeconds());
	const [selectedDate, setSelectedDate] = useState(dateTime);
	const [fullDate, setFullDate] = useState(dateTime);

	const handleCellClick = (type, value) => {
		switch (type) {
			case "hour":
				setSelectedHour(value);
				break;
			case "minute":
				setSelectedMinute(value);
				break;
			case "second":
				setSelectedSecond(value);
				break;
			default:
				break;
		}
	};

	const handleNowButtonClick = () => {
		const now = new Date();
		setSelectedHour(now.getHours());
		setSelectedMinute(now.getMinutes());
		setSelectedSecond(now.getSeconds());
		setSelectedDate(now);
		setFullDate(now); // add only if want to set NOW immediately
	};

	const handleDateChange = (days) => {
		const newDate = new Date(
			selectedDate.getFullYear(),
			selectedDate.getMonth(),
			selectedDate.getDate(),
			selectedHour,
			selectedMinute,
			selectedSecond
		);
		newDate.setDate(selectedDate.getDate() + days);
		setSelectedDate(newDate);
		setFullDate(newDate); // add only if want to set NOW immediately
	};

	useEffect(() => {
		onSave(fullDate);
	}, [fullDate, onSave]);

	useEffect(() => {
		const newDate = new Date(
			selectedDate.getFullYear(),
			selectedDate.getMonth(),
			selectedDate.getDate(),
			selectedHour,
			selectedMinute,
			selectedSecond
		);
		setFullDate(newDate);
	}, [selectedHour, selectedMinute, selectedSecond, selectedDate]);

	return (
		<div className='mb-4'>
			<input
				value={fullDate.toLocaleString("cs-CZ")}
				readOnly={true}
				type='text'
				className='input input-bordered rounded-t-lg w-full mb-3'
			/>
			<div
				tabIndex='-1'
				className='bg-white rounded-lg border border-blue-500'
			>
				<div className='flex justify-between justify-content-center'>
					<div className='pl-4 pt-4 relative'>
						<div className='current-date'>
							{selectedDate.toLocaleString("cs-CZ", {
								day: "numeric",
								month: "numeric",
								year: "numeric",
							})}
						</div>
						<div className='flex items-center space-x-2'>
							<button
								type='button'
								className='bg-white border border-blue-500 hover:bg-blue-100 text-blue-500 hover:text-blue-700 text-center font-bold py-2 px-2 rounded'
								onClick={() => handleDateChange(-1)}
							>
								<ChevronDoubleLeftIcon />
							</button>
							<button
								type='button'
								className='bg-white border border-blue-500 hover:bg-blue-100 text-blue-500 hover:text-blue-700 text-center font-bold py-2 px-2 rounded'
								onClick={() => handleDateChange(1)}
							>
								<ChevronDoubleRightIcon />
							</button>
						</div>
						<button
							type='button'
							className='bg-white border border-blue-500 hover:bg-blue-100 text-blue-500 hover:text-blue-700 text-center font-bold py-2 px-4 rounded absolute bottom-0 mb-4'
							onClick={handleNowButtonClick}
						>
							Now
						</button>
					</div>

					<div className='p-4'>
						<div className='flex space-x-2'>
							{/* Hour column */}
							<div className='flex flex-col items-center justify-between overflow-y-auto h-44'>
								{[...Array(5).keys()].map((index) => {
									const hour =
										(selectedHour + index - 2 + 24) % 24; // Center the selected hour
									const isSelected =
										selectedHour % 24 === hour;
									return (
										<div
											key={index}
											className={`${
												isSelected
													? "bg-white border border-blue-500 hover:bg-blue-100 text-blue-500 hover:text-blue-700 text-center font-bold py-2 px-3 rounded"
													: ""
											}`}
											onClick={() =>
												handleCellClick("hour", hour)
											}
										>
											{hour.toString().padStart(2, "0")}
										</div>
									);
								})}
							</div>

							{/* Minute column */}
							<div className='flex flex-col items-center justify-between overflow-y-auto h-44'>
								{[...Array(5).keys()].map((index) => {
									const minute =
										(selectedMinute + index - 2 + 60) % 60; // Center the selected minute
									const isSelected =
										selectedMinute % 60 === minute;
									return (
										<div
											key={index}
											className={`${
												isSelected
													? "bg-white border border-blue-500 hover:bg-blue-100 text-blue-500 hover:text-blue-700 text-center font-bold py-2 px-3 rounded"
													: ""
											}`}
											onClick={() =>
												handleCellClick(
													"minute",
													minute
												)
											}
										>
											{minute.toString().padStart(2, "0")}
										</div>
									);
								})}
							</div>

							{/* Second column */}
							<div className='flex flex-col items-center justify-between overflow-y-auto h-44'>
								{[...Array(5).keys()].map((index) => {
									const second =
										(selectedSecond + index - 2 + 60) % 60; // Center the selected second
									const isSelected =
										selectedSecond % 60 === second;
									return (
										<div
											key={index}
											className={`${
												isSelected
													? "bg-white border border-blue-500 hover:bg-blue-100 text-blue-500 hover:text-blue-700 text-center font-bold py-2 px-3 rounded"
													: ""
											}`}
											onClick={() =>
												handleCellClick(
													"second",
													second
												)
											}
										>
											{second.toString().padStart(2, "0")}
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TimePicker;
