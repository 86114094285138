const Alert = ({ message, type }) => {
	const alertClassName = `${
		type === "success"
			? "bg-green-100 border-green-400 text-green-700"
			: "bg-red-100 border-red-400 text-red-700"
	}`;

	return (
		<div className='fixed bottom-0 left-0 w-full z-100'>
			<div
				className={`${alertClassName} p-4 uppercase rounded items-center m-2`}
			>
				<span>{message}</span>
			</div>
		</div>
	);
};

export default Alert;
