const DataTableSkeleton = () => {
	const renderSkeletons = () => {
		const skeletons = [];
		for (let i = 0; i < 5; i++) {
			skeletons.push(
				<tr key={i}>
					<td>
						<div className='loading-bar h-4 w-full rounded mb-2'></div>
					</td>
					<td>
						<div className='loading-bar h-4 w-full rounded mb-2'></div>
					</td>
				</tr>
			);
		}
		return skeletons;
	};

	return (
		<table className='table'>
			<tbody>{renderSkeletons()}</tbody>
		</table>
	);
};
export default DataTableSkeleton;
