import { useEffect, useState } from "react";
import Header from "../components/layout/Header";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import KbSkeleton from "../components/skeleton/KbSkeleton";
import Article from "../components/kb/Article";
import { useTranslation } from "react-i18next";

const KnowledgeBase = () => {
	const { t, i18n } = useTranslation();
	const axiosPrivate = useAxiosPrivate();

	const [kb, setKb] = useState([]);
	const [selectedTypes, setSelectedTypes] = useState([]);

	const [loading, setLoading] = useState(true);

	const handleGetKb = async () => {
		try {
			const response = await axiosPrivate.post(`getKb/${i18n.language}`);
			const data = response?.data?.data;
			setKb(data);
			setLoading(false);
		} catch (error) {
			console.error("Error:", error);
		}
	};

	const kbTypes = {
		2: t("instruction"),
		3: t("manual"),
	};

	const typeFilter = [
		{ id: "2", name: t("instruction") },
		{ id: "3", name: t("manual") },
	];

	const handleTypeCheckboxChange = (typeId) => {
		setSelectedTypes((prevSelectedTypes) => {
			if (prevSelectedTypes.includes(typeId)) {
				// If the item is already in the array, remove it
				return prevSelectedTypes.filter((item) => item !== typeId);
			} else {
				// If the item is not in the array, add it
				return [...prevSelectedTypes, typeId];
			}
		});
	};

	useEffect(() => {
		setKb([]);
		setLoading(true);
		handleGetKb();
	}, [t]);

	return (
		<div>
			<Header headerName={"knowledgeBase"} />
			<div className='mx-3 mt-5'>
				<div className='types flex overflow-x-auto scrollbar-hidden'>
					{typeFilter.map((type) => (
						<label
							key={type["id"]}
							className={`mr-2 mb-3 inline-flex items-center cursor-pointer bg-white border text-center font-bold py-1 px-3 rounded-lg whitespace-nowrap shadow-md ${
								selectedTypes.includes(type["id"])
									? "border-blue-500 text-blue-500"
									: "border-gray-500 text-gray-500"
							}`}
						>
							<input
								type='checkbox'
								className='hidden'
								checked={selectedTypes.includes(type["id"])}
								onChange={() =>
									handleTypeCheckboxChange(type["id"])
								}
							/>
							<span>{type["name"] ? type["name"] : "N/A"}</span>
						</label>
					))}
				</div>
				<div className='articles'>
					{loading && <KbSkeleton />}
					{kb.map((article) => {
						return (
							(selectedTypes.length === 0 ||
								selectedTypes.includes(article.type_id)) && (
								<Article
									key={article.id}
									article={article}
									articles={kb}
									type={kbTypes[article.type_id]}
								/>
							)
						);
					})}
					{!loading && kb.length === 0 && (
						<p className='text-center mt-2 text-sm'>
							{t("noDataAvailable")}
						</p>
					)}
				</div>
			</div>
		</div>
	);
};
export default KnowledgeBase;
