import { useEffect, useRef, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import CustomDateTimePicker from "../layout/CustomDateTimePicker";
import { CameraIcon, InfoIcon, RefreshIcon } from "../Icons";
import WebcamComponent from "../camera/WebCamera";
import CapturedPhotos from "../camera/CapturedPhotos";
import { useTranslation } from "react-i18next";
import Alert from "../layout/Alert";

const DeviceLineInit = ({
	line,
	dev,
	hasPhotos,
	setHasPhotos,
	setVideoRefs,
	stopCamera,
}) => {
	const axiosPrivate = useAxiosPrivate();

	const { t } = useTranslation();

	const [isOpen, setIsOpen] = useState(false);
	const [isCameraOpen, setIsCameraOpen] = useState(false);

	const [capturedImagesData, setCapturedImagesData] = useState([]);
	const [capturedImagesUrl, setCapturedImagesUrl] = useState([]);

	const [supplyName, setSupplyName] = useState(line?.supply_point_name ?? "");
	const [currReading, setCurrReading] = useState(
		line?.original_entered_reading ?? "0"
	);
	const [resetCounter, setResetCounter] = useState(false);
	const [batteryChange, setBatteryChange] = useState(false);
	const [impulseRatio, setImpulseRatio] = useState(
		line?.pulse_conversion ?? "0"
	);
	const [originalEnteredDate, setOriginalEnteredDate] = useState(
		line?.original_entered_time
			? line.original_entered_time * 1000
			: Math.round(Date.now() / 1000) * 1000
	);
	const [dateTime, setDateTime] = useState(new Date(originalEnteredDate));
	const [existPhoto, setExistPhoto] = useState(line?.has_image > 0);
	const [isInitialized, setIsInitialized] = useState(false);
	const [formChanged, setFormChanged] = useState(false);

	const [savingForm, setSavingForm] = useState(false);

	const [needPhoto, setNeedPhoto] = useState(!existPhoto);
	const [newPhotoAdded, setNewPhotoAdded] = useState(false);

	const [alertMessage, setAlertMessage] = useState("");
	const [alertType, setAlertType] = useState("success");
	const [showAlert, setShowAlert] = useState(false);
	const handleSetAlert = (message, type) => {
		setAlertMessage(message);
		setAlertType(type);
		setShowAlert(true);
		setTimeout(() => {
			setShowAlert(false);
		}, 2000);
	};

	const isPulse = line.pulse_conversion;

	const videoRef = useRef(null);

	const handleGetAttachment = async () => {
		try {
			const response = await axiosPrivate.post(
				`getAttachment/` + dev.vhost_id + `/` + line.id
			);
			const data = response.data.data;
			const newUrls = data.map((el) => el.path);
			setCapturedImagesUrl(newUrls);
		} catch (error) {
			console.error("Error:", error);
		}
	};

	const handleModal = () => {
		setIsOpen(!isOpen);
		if (!isOpen) {
			handleGetAttachment();
		} else {
			stopCamera(videoRef);
			setIsCameraOpen(false);
		}
	};

	const handleOpenCamera = () => {
		if (isCameraOpen) {
			stopCamera(videoRef);
		}
		setIsCameraOpen(!isCameraOpen);
	};

	const handleSaveForm = async () => {
		// if (needPhoto && !newPhotoAdded) {
		// 	handleSetAlert(t("alertNewPhotos"), "error");
		// 	return;
		// }
		// if (
		// 	formChanged &&
		// 	dateTime.getTime() === new Date(originalEnteredDate).getTime()
		// ) {
		// 	handleSetAlert(t("alertErrorDiffTime"), "error");
		// 	return;
		// }
		const formData = new FormData();

		capturedImagesData.forEach((blob, index) => {
			formData.append(
				"image" + index,
				blob,
				`${blob.name}-captured-image-${index}.${
					blob.type.split("/")[1]
				}`
			);
		});
		formData.append("deviceLineId", line.id ?? 0);
		formData.append("deviceCode", dev.device_code ?? "");
		formData.append("vhostUrl", dev.vhost_url ?? "");
		formData.append("vhostId", dev.vhost_id ?? 0);
		formData.append("formChanged", formChanged);
		formData.append("supplyName", supplyName);
		formData.append("currReading", currReading);
		formData.append("impulseRatio", impulseRatio);
		formData.append("resetCounter", resetCounter ? 1 : 0);
		formData.append("batteryChange", batteryChange ? 1 : 0);
		formData.append("isPulse", isPulse);
		formData.append("dateTime", Math.floor(dateTime.getTime() / 1000));

		try {
			setSavingForm(true);
			const response = await axiosPrivate.post(
				"initializationUpload",
				formData
			);

			if (response.status === 200) {
				if (formChanged) {
					handleSetAlert(t("alertSuccessSave"), "success");
				} else {
					handleSetAlert(t("alertSuccessSaveAtt"), "success");
				}
				setSavingForm(false);
				setCapturedImagesData([]);
				setExistPhoto(true);
				setNeedPhoto(false);
				setNewPhotoAdded(false);
				setResetCounter(false);
				setBatteryChange(false);
				if (hasPhotos === 1 || hasPhotos === 0) {
					setHasPhotos(1);
				}
				if (isCameraOpen) {
					handleOpenCamera();
				}
				handleGetAttachment();
			} else {
				setSavingForm(false);
				handleSetAlert(t("alertErrorSave"), "error");
				throw new Error(`HTTP error! Status: ${response.status}`);
			}
		} catch (error) {
			setSavingForm(false);
			console.error("Error:", error);
			handleSetAlert(t("alertErrorSave"), "error");
		}
	};

	useEffect(() => {
		if (
			isPulse &&
			(currReading !== (line?.original_entered_reading ?? "0") ||
				resetCounter ||
				impulseRatio !== (line?.pulse_conversion ?? "0") ||
				dateTime.getTime() !== new Date(originalEnteredDate).getTime())
		) {
			setFormChanged(true);
			setNeedPhoto(true);
		} else {
			setFormChanged(false);
			if (existPhoto) {
				setNeedPhoto(false);
			}
		}
	}, [currReading, impulseRatio, dateTime, resetCounter]);

	useEffect(() => {
		setIsInitialized(
			isPulse
				? currReading !== "0" && impulseRatio !== "0" && existPhoto
					? 2
					: currReading === "0" && impulseRatio === "0" && !existPhoto
					? 0
					: 1
				: existPhoto
				? 2
				: 0
		);
	}, [currReading, impulseRatio, existPhoto]);

	useEffect(() => {
		if (isCameraOpen) {
			setVideoRefs((prev) => {
				return [...prev, videoRef];
			});
		}
	}, [videoRef, isCameraOpen]);
	return (
		<div className={`openedinit2-${isOpen}`}>
			<div className='rounded-lg bg-white border border-gray-500 mb-3 shadow-md'>
				<div className='p-3' onClick={() => handleModal()}>
					<div className='items-center justify-between'>
						<div className='flex items-center justify-between'>
							<p className='text-xl font-medium'>
								{line.description}
							</p>
							<div className='flex flex-col gap-2'>
								<div className='icons flex justify-center'>
									{isPulse && (
										<span
											className={
												isInitialized === 2
													? "text-green-500"
													: isInitialized === 0
													? "text-red-500"
													: "text-yellow-500"
											}
										>
											<InfoIcon />
										</span>
									)}
									<span
										className={`ml-2 ${
											existPhoto
												? `text-green-500`
												: `text-red-500`
										}`}
									>
										<CameraIcon />
									</span>
									<span
										className={`ml-2 ${
											line?.original_entered_reading
												? `text-green-500`
												: `text-red-500`
										}`}
									>
										DATA
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>

				{isOpen && (
					<>
						{line && (
							<form autoComplete='off' className='mx-3 mb-3'>
								{isPulse && (
									<>
										<label>{t("currentReading")}</label>
										<input
											onChange={(e) => {
												setCurrReading(e.target.value);
											}}
											onFocus={(e) => {
												if (
													parseFloat(
														e.target.value ?? 0
													) === 0
												) {
													e.target.select();
												}
											}}
											onBlur={(e) => {
												if (
													e.target.value.trim() === ""
												) {
													setCurrReading("0");
												}
											}}
											value={currReading}
											type='number'
											onWheel={(e) => e.target.blur()}
											className='input input-bordered w-full mb-3'
										/>
										<label>{t("impulseRatio")}</label>
										<input
											onChange={(e) => {
												setImpulseRatio(e.target.value);
											}}
											onFocus={(e) => {
												if (
													parseFloat(
														e.target.value ?? 0
													) === 0
												) {
													e.target.select();
												}
											}}
											onBlur={(e) => {
												if (
													e.target.value.trim() === ""
												) {
													setImpulseRatio("0");
												}
											}}
											value={impulseRatio}
											type='number'
											onWheel={(e) => e.target.blur()}
											className='input input-bordered w-full mb-3'
										/>
										<label>{t("spName")}</label>
										<input
											onChange={(e) => {
												setSupplyName(e.target.value);
											}}
											onFocus={(e) => {
												if (
													e.target.value ===
													line?.supply_point_name
												) {
													e.target.select();
												}
											}}
											value={supplyName}
											type='value'
											className='input input-bordered w-full mb-3'
										/>
										<label>{t("timeAndDate")}</label>
										<CustomDateTimePicker
											dateTime={dateTime}
											onSave={(date) => setDateTime(date)}
										/>
									</>
								)}
								<div className='mb-3'>
									{isPulse && (
										<label className='relative inline-flex items-center cursor-pointe mr-2'>
											<input
												type='checkbox'
												checked={resetCounter}
												onChange={() =>
													setResetCounter(
														!resetCounter
													)
												}
												className='sr-only peer'
											/>
											<div className="w-11 h-6 bg-gray-500 rounded-full peer dark:bg-gray-500 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-500 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-500 peer-checked:bg-blue-500"></div>
											<span className='ml-3 text-sm'>
												{t("resetCounter")}
											</span>
										</label>
									)}
									<label className='relative inline-flex items-center cursor-pointer'>
										<input
											type='checkbox'
											checked={batteryChange}
											onChange={() =>
												setBatteryChange(!batteryChange)
											}
											className='sr-only peer'
										/>
										<div className="w-11 h-6 bg-gray-500 rounded-full peer dark:bg-gray-500 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-500 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-500 peer-checked:bg-blue-500"></div>
										<span className='ml-3 text-sm'>
											{t("batteryChange")}
										</span>
									</label>
								</div>
								{isCameraOpen && (
									<WebcamComponent
										key={line.id}
										setCapturedImagesData={
											setCapturedImagesData
										}
										capturedImagesData={capturedImagesData}
										setCapturedImagesUrl={
											setCapturedImagesUrl
										}
										capturedImagesUrl={capturedImagesUrl}
										setNeedPhoto={setNeedPhoto}
										setNewPhotoAdded={setNewPhotoAdded}
										videoRef={videoRef}
									/>
								)}
								{capturedImagesUrl && (
									<CapturedPhotos
										key={`photos${line.id}`}
										setCapturedImagesData={
											setCapturedImagesData
										}
										capturedImagesData={capturedImagesData}
										setCapturedImagesUrl={
											setCapturedImagesUrl
										}
										capturedImagesUrl={capturedImagesUrl}
										setExistPhoto={setExistPhoto}
										setNeedPhoto={setNeedPhoto}
										setNewPhotoAdded={setNewPhotoAdded}
										handleSetAlert={handleSetAlert}
									/>
								)}
								<div className='flex justify-between'>
									<button
										onClick={() => handleOpenCamera()}
										type='button'
										className='bg-white border border-gray-500 hover:bg-gray-100 text-gray-500 hover:text-gray-700 text-center font-bold py-2 px-4 rounded'
									>
										{isCameraOpen
											? t("closeCamera")
											: t("openCamera")}
									</button>
									<button
										onClick={() => handleSaveForm()}
										type='button'
										className='bg-white border border-green-500 hover:bg-green-100 text-green-500 hover:text-green-700 text-center font-bold py-2 px-4 rounded'
									>
										{!savingForm && t("save")}
										{savingForm && (
											<RefreshIcon
												isRefresh={savingForm}
											/>
										)}
									</button>
								</div>
								{showAlert && (
									<Alert
										message={alertMessage}
										type={alertType}
									/>
								)}
							</form>
						)}
					</>
				)}
			</div>
		</div>
	);
};
export default DeviceLineInit;
