import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const LanguageSwitcher = () => {
	const { i18n } = useTranslation();

	const changeLanguage = (lng) => {
		i18n.changeLanguage(lng);
		localStorage.setItem("language", lng);
	};

	// useEffect(() => {
	// 	const savedLanguage = localStorage.getItem("language");
	// 	if (savedLanguage) {
	// 		i18n.changeLanguage(savedLanguage);
	// 	}
	// }, [i18n]);

	return (
		<>
			<li>
				<button onClick={() => changeLanguage("en")}>English</button>
			</li>
			<li>
				<button onClick={() => changeLanguage("cs")}>Čeština</button>
			</li>
		</>
	);
};

export default LanguageSwitcher;
