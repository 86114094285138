import Header from "../components/layout/Header";
import Reassign from "../components/reassign/Reassign";

const DeviceReassign = ({ keepValue = false }) => {
	return (
		<div>
			<Header headerName={"deviceReassign"} />
			<Reassign keepValue={keepValue} />
		</div>
	);
};
export default DeviceReassign;
