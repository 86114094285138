import { useRef, useState } from "react";
import DeviceLineInit from "../device/DeviceLineInit";
import { CloseIcon } from "../Icons";

const InstalationModal = ({ lines, dev, onClose, hasPhotos, setHasPhotos }) => {
	const [videoRefs, setVideoRefs] = useState([]);

	const modalRef = useRef();

	const stopCamera = (vr) => {
		if (vr.current && vr.current.srcObject) {
			vr.current.srcObject.getTracks().forEach((track) => {
				track.stop();
			});
			vr.current.srcObject = null;
			setVideoRefs([]);
		}
	};

	const closeModal = () => {
		videoRefs.map((vr) => {
			stopCamera(vr);
			return true;
		});
		onClose();
	};

	return (
		<div className='p-2 fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-30'>
			<div
				ref={modalRef}
				className='rounded bg-white w-full h-full mx-auto p-4 pt-5 flex flex-col'
			>
				<div>
					<button
						className='btn btn-sm btn-circle btn-ghost text-black absolute top-3 right-3 shadow-inner border-gray-200'
						onClick={() => closeModal()}
					>
						<CloseIcon />
					</button>
					<p className='text-2xl text-blue-500 font-bold mb-2'>{dev.device_name}</p>
					<p className='text-lg font-bold mb-2'>
						{dev.device_description}
					</p>
				</div>
				<div className='flex-1 overflow-y-auto'>
					{lines.map((line) => (
						<DeviceLineInit
							key={line.id}
							line={line}
							dev={dev}
							hasPhotos={hasPhotos}
							setHasPhotos={(val) => setHasPhotos(val)}
							setVideoRefs={setVideoRefs}
							stopCamera={stopCamera}
						/>
					))}
				</div>
			</div>
		</div>
	);
};

export default InstalationModal;
