import { useTranslation } from "react-i18next";
import Device from "../../instalation/Device";
import { useState } from "react";

const DeviceSearch = ({ devices }) => {
	const { t } = useTranslation();

	const devLoadNum = 2;

	const [displayedDevices, setDisplayedDevices] = useState(devLoadNum);

	const loadMoreDevices = () => {
		setDisplayedDevices((prevCount) => prevCount + devLoadNum);
	};

	return (
		devices &&
		devices.length > 0 && (
			<div className='devices mb-3'>
				<h2>{t("devices")}</h2>
				{devices.slice(0, displayedDevices).map((dev) => (
					<Device key={`search-${dev.device_code}`} dev={dev} />
				))}
				{devices.length > displayedDevices && (
					<button
						type='button'
						className='bg-white border border-gray-500 hover:bg-gray-100 text-gray-500 hover:text-gray-700 text-center font-bold py-2 px-4 rounded w-full'
						onClick={loadMoreDevices}
					>
						{t("loadMore")}
					</button>
				)}
			</div>
		)
	);
};
export default DeviceSearch;
