import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import OrderModal from "./OrderModal";

const Order = ({ wo, wos }) => {
	const { t, i18n } = useTranslation();
	const [isModalOpen, setIsModalOpen] = useState(false);

	const [status, setStatus] = useState(wo.status_id);

	const formattedWorkDate = wo.workd_date
		? new Date(wo.work_date * 1000).toLocaleDateString()
		: "";
	const formattedWorkTime = wo.work_time
		? new Date(
				(parseInt(wo.work_date) + parseInt(wo.work_time)) * 1000
		  ).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
		: "";

	const handleModal = (state) => {
		setIsModalOpen(state);
	};

	const findStatusNameById = (id) => {
		const status = statuses.find((status) => status.id === id);
		return status ? status.name : "";
	};

	const color =
		status == "1"
			? "gray"
			: status == "2"
			? "blue"
			: status == "3"
			? "green"
			: status == "3"
			? "red"
			: "black";

	const statuses = [
		// { id: "1", name: t("draft") },
		{ id: "2", name: t("confirmedByClient") },
		{ id: "3", name: t("finish") },
		{ id: "4", name: t("interrupted") },
		{ id: "5", name: t("readyToBill") },
		{ id: "6", name: t("closed") },
	];

	useEffect(() => {
		const disableScroll = () => {
			document.body.classList.add("overflow-hidden");
		};
		const enableScroll = () => {
			document.body.classList.remove("overflow-hidden");
		};

		if (isModalOpen) {
			disableScroll();
		} else {
			enableScroll();
		}

		return () => {
			enableScroll(); // Enable scroll when the component is unmounted
		};
	}, [isModalOpen]);

	useEffect(() => {
		wo.status_id = status;
	}, [status]);

	return (
		<div>
			<div
				onClick={() => handleModal(true)}
				className={`p-3 rounded-lg bg-white-200 border border-${color}-500 rounded-full mb-3 shadow-md`}
			>
				<div className=''>
					<div
						className={`text-xl font-medium text-${color}-500 w-full`}
					>
						{wo.site_text}
					</div>
					<div className='text-xs font-medium w-full'>
						<div className='text-gray-500 mr-2'>
							{t("status")}: {findStatusNameById(status)}
						</div>
						<div className='text-gray-500 mr-2'>
							{t("vhost")}: {wo.vhost_name}
						</div>
						<div className='text-gray-500 mr-2'>
							{t("instalComp")}: {wo.ic_name}
						</div>
					</div>
					<div className='flex flex-col gap-2'>
						<div className='text-gray-500'>
							{formattedWorkDate} {formattedWorkTime}
						</div>
					</div>
				</div>
			</div>
			{isModalOpen && (
				<OrderModal
					order={wo}
					orders={wos}
					statuses={statuses}
					setStatus={(st) => setStatus(st)}
					onClose={() => handleModal(false)}
				/>
			)}
		</div>
	);
};

export default Order;
