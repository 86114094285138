import { useEffect, useState } from "react";
import Select from "react-select";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useTranslation } from "react-i18next";

const ChannelSelect = ({ onSelect, channelsArr, type = "", label = "channel" }) => {
	const axiosPrivate = useAxiosPrivate();

	const { t } = useTranslation();

	const [channels, setChannels] = useState([]);
	const [selectedChannel, setSelectedChannel] = useState(-1);
	const [selectedChannelName, setSelectedChannelName] = useState("");

	const handleSelectedChannel = (selectedOption) => {
		setSelectedChannel(selectedOption.value);
		setSelectedChannelName(selectedOption.label);
	};

	useEffect(() => {
		setSelectedChannel(-1);
		setChannels(
			channelsArr.map((channel) => ({
				value: channel.id,
				label: channel.channel_name,
			}))
		);
	}, [channelsArr]);

	useEffect(() => {
		onSelect(selectedChannel);
	}, [selectedChannel, selectedChannelName, onSelect]);

	return (
		<div className='shadow-md'>
			<label>{t(label)}</label>
			<Select
				options={channels}
				value={channels.find(
					(option) => option.value === selectedChannel
				)}
				onChange={handleSelectedChannel}
				isSearchable
				placeholder={t("select")}
				className='mb-3'
			/>
		</div>
	);
};

export default ChannelSelect;
