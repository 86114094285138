import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";

import axios from "../api/axios";
import useAuth from "../hooks/useAuth";

import Alert from "../components/layout/Alert";

const Login = () => {
	const { setAuth, setIsLogin } = useAuth();

	const navigate = useNavigate();
	const location = useLocation();
	const from = location.state?.from?.pathname || "/";

	const { t } = useTranslation();
	const [otp, setOtp] = useState(new Array(7).fill(""));
	const [activeOtp, setActiveOtp] = useState(0);
	const btnNb = [1, 2, 3, 4, 5, 6, 7, 8, 9, "res", 0, "del"];

	const [wrongOtp, setWrongOtp] = useState(false);

	const [alertMessage, setAlertMessage] = useState("");
	const [alertType, setAlertType] = useState("success");
	const [showAlert, setShowAlert] = useState(false);
	const handleSetAlert = (message, type) => {
		setAlertMessage(message);
		setAlertType(type);
		setShowAlert(true);
		setTimeout(() => {
			setShowAlert(false);
		}, 2000);
	};

	const otpRef = useRef(null);

	const handleSetOtp = (e, idx) => {
		e.preventDefault();
		const { value } = e.target;
		const newOtp = [...otp];
		newOtp[idx] = value.substring(value.length - 1);
		if (value === "") {
			setActiveOtp(idx - 1);
		} else {
			setActiveOtp(idx + 1);
		}
		setOtp(newOtp);
	};

	const handleBtnClick = (e, idx) => {
		e.preventDefault();
		const newOtp = [...otp];
		if (idx === "del" && activeOtp >= 0) {
			if (otp[activeOtp] === "") {
				newOtp[activeOtp - 1] = "";
				if (activeOtp !== 0) {
					setActiveOtp(activeOtp - 1);
				}
			} else {
				newOtp[activeOtp] = "";
				if (activeOtp !== 0) {
					setActiveOtp(activeOtp);
				}
			}
			setOtp(newOtp);
		} else if (idx === "res") {
			setActiveOtp(0);
			setOtp(new Array(7).fill(""));
		} else {
			newOtp[activeOtp] = idx;
			if (activeOtp + 1 < otp.length) {
				setActiveOtp(activeOtp + 1);
			}
			setOtp(newOtp);
		}
	};

	useEffect(() => {
		otpRef.current?.focus();
	}, [activeOtp]);

	useEffect(() => {
		const handleWrongLogin = () => {
			setWrongOtp(true);
			setActiveOtp(0);
			setOtp(new Array(7).fill(""));
			const wrongOtpTimeout = setTimeout(() => {
				setWrongOtp(false);
			}, 500);
			return () => clearTimeout(wrongOtpTimeout);
		};
		const handleLogin = async () => {
			setIsLogin(true);
			try {
				const response = await axios.post(
					`/login?token=` + JSON.stringify(otp)
				);
				const accessToken = response?.data?.data?.jwt;
				if (accessToken) {
					setAuth({ accessToken }); // set accessToken to auth provider
					navigate(from);
				} else {
					handleWrongLogin();
				}
			} catch (error) {
				if (error.response.data.status === 403) {
					handleSetAlert(t("alertBlockedIp"), "error");
				}
				console.error("Error:", error);
				handleWrongLogin();
			}
		};
		const isOtpValid = otp.every(
			(value) => typeof value === "number" && value !== ""
		);
		if (isOtpValid) {
			handleLogin();
		}
	}, [otp, navigate, setAuth, from, setIsLogin, t]);
	return (
		<form autoComplete='off'>
			<div className='bg-white'>
				<div className='min-h-screen flex flex-col justify-center items-center'>
					<h1 className='text-3xl text-center mb-3'>
						{t("appName")}
					</h1>
					<div className='flex flex-wrap justify-center items-center space-x-1 mb-8'>
						{otp.map((val, idx) => (
							<React.Fragment key={idx}>
								<input
									ref={idx === activeOtp ? otpRef : null}
									type={idx >= 3 ? "password" : "number"}
									value={val}
									autoComplete='off'
									className={`${
										idx === activeOtp
											? "border-blue-500"
											: "border-gray-400"
									} ${val !== "" ? "border-green-500" : ""} ${
										wrongOtp ? "border-red-500" : ""
									} w-12 h-12 border-2 rounded-full outline-none text-center transition duration-300 shadow-inner`}
									onChange={(e) => handleSetOtp(e, idx)}
									readOnly={true}
								/>
								{idx === 2 && (
									<span className='mx-2 text-gray-500'>
										-
									</span>
								)}
							</React.Fragment>
						))}
					</div>
					<div className='grid grid-cols-3 gap-4 max-w-md mx-auto'>
						{btnNb.map((val, idx) => (
							<React.Fragment key={idx}>
								{val !== "" && (
									<button
										type='button'
										className='btn bg-white w-20 h-20 border-2 border-gray-400 rounded-full outline-none text-center text-lg font-medium hover:border-blue-500 focus:border-blue-500 transition duration-300 shadow-inner'
										onClick={(e) => handleBtnClick(e, val)}
									>
										{val === "del" ? <div>DEL</div> : val}
									</button>
								)}
								{val === "" && <div></div>}
							</React.Fragment>
						))}
					</div>
				</div>
			</div>
			{showAlert && <Alert message={alertMessage} type={alertType} />}
		</form>
	);
};
export default Login;
