import { useEffect, useRef, useState } from "react";
import { CloseIcon } from "../Icons";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useTranslation } from "react-i18next";
import Article from "./Article";
import KbSkeleton from "../skeleton/KbSkeleton";

const KbModal = ({ dev, onClose, articleModal }) => {
	const { t, i18n } = useTranslation();

	const axiosPrivate = useAxiosPrivate();

	const [kb, setKb] = useState([]);
	const [selectedTypes, setSelectedTypes] = useState([]);

	const [loading, setLoading] = useState(true);

	const modalRef = useRef();

	const handleGetKb = async () => {
		try {
			const response = await axiosPrivate.post(
				`getKbDev/${i18n.language}/${dev.catalogue_id}`
			);
			const data = response?.data?.data;
			setKb(data);
			setLoading(false);
		} catch (error) {
			console.error("Error:", error);
		}
	};

	const kbTypes = {
		2: t("instruction"),
		3: t("manual"),
	};

	const typeFilter = [
		{ id: "2", name: t("instruction") },
		{ id: "3", name: t("manual") },
	];

	const handleTypeCheckboxChange = (typeId) => {
		setSelectedTypes((prevSelectedTypes) => {
			if (prevSelectedTypes.includes(typeId)) {
				// If the item is already in the array, remove it
				return prevSelectedTypes.filter((item) => item !== typeId);
			} else {
				// If the item is not in the array, add it
				return [...prevSelectedTypes, typeId];
			}
		});
	};

	useEffect(() => {
		handleGetKb();
	}, []);

	return (
		<div className='p-2 fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-30'>
			<div
				ref={modalRef}
				className='rounded bg-white w-full h-full mx-auto p-4 pt-5 flex flex-col'
			>
				<div>
					<button
						className='btn btn-sm btn-circle btn-ghost text-black absolute top-3 right-3 shadow-inner border-gray-200'
						onClick={() => onClose()}
					>
						<CloseIcon />
					</button>
					<p className='text-2xl text-blue-500 font-bold mb-2'>
						{t("knowledgeBase")}
					</p>
					<p className='text-xs text-gray-500 font-bold mb-3'>
						{`${dev.device_name} - ${
							dev.connector ? dev.connector : "N/A"
						}`}
					</p>
				</div>
				<div className='types flex overflow-x-auto scrollbar-hidden'>
					{typeFilter.map((type) => (
						<label
							key={type["id"]}
							className={`mr-2 mb-3 inline-flex items-center cursor-pointer bg-white border text-center font-bold py-1 px-3 rounded-lg whitespace-nowrap shadow-md ${
								selectedTypes.includes(type["id"])
									? "border-blue-500 text-blue-500"
									: "border-gray-500 text-gray-500"
							}`}
						>
							<input
								type='checkbox'
								className='hidden'
								checked={selectedTypes.includes(type["id"])}
								onChange={() =>
									handleTypeCheckboxChange(type["id"])
								}
							/>
							<span>{type["name"] ? type["name"] : "N/A"}</span>
						</label>
					))}
				</div>
				<div className='flex-1 overflow-y-auto'>
					{loading && <KbSkeleton />}
					{kb.map((article) => {
						return (
							(selectedTypes.length === 0 ||
								selectedTypes.includes(article.type_id)) && (
								<Article
									key={article.id}
									article={article}
									articles={kb}
									type={kbTypes[article.type_id]}
									articleModal={articleModal}
								/>
							)
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default KbModal;
