import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from "../Icons";

const NavigationButtons = ({ onClickPrev, onClickNext, addedStyle = "" }) => (
	<div className={`flex ${addedStyle}`}>
		<button
			type='button'
			className='bg-white border border-gray-500 hover:bg-gray-100 text-gray-500 hover:text-gray-700 text-center font-bold py-2 px-4 rounded-full mr-2'
			onClick={onClickPrev}
		>
			<ChevronDoubleLeftIcon />
		</button>
		<button
			type='button'
			className='bg-white border border-gray-500 hover:bg-gray-100 text-gray-500 hover:text-gray-700 text-center font-bold py-2 px-4 rounded-full'
			onClick={onClickNext}
		>
			<ChevronDoubleRightIcon />
		</button>
	</div>
);
export default NavigationButtons;
