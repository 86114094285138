const DeviceLineSkeleton = () => {
	const renderSkeletons = () => {
		const skeletons = [];
		for (let i = 0; i < 2; i++) {
			skeletons.push(
				<div key={i} className='skeleton-device-line'>
					<div
						className={`bg-white-200 py-2 ${
							i === 1
								? ""
								: "border-b border-solid border-gray-500"
						}`}
					>
						<div className='flex'>
							<div className='text-xl font-medium w-1/2'>
								<div className='loading-bar h-6 w-4/5 rounded mb-2'></div>
								<div className='loading-bar h-4 w-2/5 rounded'></div>
							</div>
							<div className='w-1/2'>
								<div className='loading-bar h-6 w-4/5 float-right rounded mb-2'></div>
								<div className='loading-bar h-4 w-2/5 float-right rounded'></div>
							</div>
						</div>
					</div>
				</div>
			);
		}
		return skeletons;
	};

	return <div>{renderSkeletons()}</div>;
};

export default DeviceLineSkeleton;
