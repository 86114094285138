import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import Home from './pages/Home';
import Login from './pages/Login';
import Layout from './pages/Layout';
import NotFound from './pages/NotFound';
import Instalation from './pages/Instalation';
import InstalationLocation from './pages/InstalationLocation';
import KnowledgeBase from './pages/KnowledgeBase';

import RequireAuth from './components/RequireAuth';
import useAuth from './hooks/useAuth';
import PersistLogin from './components/PersistLogin';
import DeviceReassign from './pages/DeviceReassign';
import WorkOrder from './pages/WorkOrder';

function App() {
    const { auth } = useAuth();
    return (
        <BrowserRouter>
            <div>
                <Routes>
                    <Route path="/" element={<Layout />}>
                        <Route element={<PersistLogin />}>
                            {/* public routes */}
                            <Route
                                path='login'
                                element={auth.accessToken ? <Navigate to='/' /> : <Login />} // if logged and open /login page -> redirect to home
                            />
                            {/* protected routes */}
                            <Route element={<RequireAuth />}>
                                <Route path='' element={<Home />} />
                                <Route path='instalation' element={<Instalation />} />
                                <Route path='instalation/location' element={<InstalationLocation />} />
                                <Route path='knowledge-base' element={<KnowledgeBase />} />
                                <Route path='device-reassign' element={<DeviceReassign />} />
                                <Route path='work-order' element={<WorkOrder />} />
                            </Route>
                        </Route>
                        {/* not found route */}
                        <Route path='*' element={<NotFound />} />
                    </Route>
                </Routes>
            </div>
        </BrowserRouter >
    );
}

export default App;