import { useEffect, useRef, useState } from "react";
import { CloseIcon } from "../Icons";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import ArticleSkeleton from "../skeleton/ArticleSkeleton";
import NavigationButtons from "../layout/NavigationButtons";

const ArticleModal = ({ article, articles, onClose }) => {
	const modalRef = useRef();
	const axiosPrivate = useAxiosPrivate();

	const [curArticle, setCurArticle] = useState(article);
	const [curArticleIndex, setCurArticleIndex] = useState(
		articles.findIndex((a) => a.id === article.id)
	);

	const [articleDetail, setArticleDetail] = useState([]);
	const [loading, setLoading] = useState(true);

	const handleGetKb = async () => {
		try {
			const response = await axiosPrivate.post(
				`getKbDetail/${article.lang_id}/${article.id}`
			);
			const data = response?.data?.data;
			setArticleDetail(data);
			setLoading(false);
		} catch (error) {
			console.error("Error:", error);
		}
	};

	const showArticle = (index) => {
		const newIndex = (index + articles.length) % articles.length;
		setCurArticle(articles[newIndex]);
		setCurArticleIndex(newIndex);
	};

	const html_entity_decode = (str) => {
		var doc = new DOMParser().parseFromString(str, "text/html");
		return doc.body.textContent || "";
	};

	useEffect(() => {
		handleGetKb();
	}, [curArticle]);

	return (
		<div className='p-2 fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-30'>
			<div
				ref={modalRef}
				className='rounded bg-white w-full h-full p-4 pt-5 flex flex-col'
			>
				<div className='first-part mb-2'>
					<button
						className='btn btn-sm btn-circle btn-ghost text-black absolute top-3 right-3 shadow-inner border-gray-200'
						onClick={() => onClose()}
					>
						<CloseIcon />
					</button>
				</div>
				<p className='font-bold text-xl mb-2'>{curArticle.title}</p>
				<div className='scrollable-part flex-1 overflow-y-auto'>
					<p className='text-gray-500 mb-2 articlePerex'>
						{curArticle.perex}
					</p>
					{loading && <ArticleSkeleton />}
					{articleDetail.map((art) => {
						return (
							<div key={art.id}>
								<div
									className='mb-2 articleText'
									dangerouslySetInnerHTML={{
										__html: html_entity_decode(
											art.article_title
										),
									}}
								/>
								<div
									className='mb-4 articleText'
									dangerouslySetInnerHTML={{
										__html: html_entity_decode(
											art.article_text
										),
									}}
								/>
							</div>
						);
					})}
				</div>
				{true && (
					<div className='flex items-center mt-4'>
						{articles?.length > 1 && (
							<NavigationButtons
								onClickPrev={() =>
									showArticle(curArticleIndex - 1)
								}
								onClickNext={() =>
									showArticle(curArticleIndex + 1)
								}
								addedStyle='justify-between w-full'
							/>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

export default ArticleModal;
