import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { CloseIcon } from "../Icons";
import DeviceSearch from "./parts/DeviceSearch";
import KbSearch from "./parts/KbSearch";
import DeviceSkeleton from "../skeleton/DeviceSkeleton";
import KbSkeleton from "../skeleton/KbSkeleton";

const SearchModal = ({ onClose }) => {
	const { t, i18n } = useTranslation();
	const axiosPrivate = useAxiosPrivate();

	const [output, setOutput] = useState({});
	const [searchText, setSearchText] = useState("");

	const [loadingDev, setLoadingDev] = useState(false);
	const [loadingKb, setLoadingKb] = useState(false);
	const [loaded, setLoaded] = useState(false);

	const inputRef = useRef(null);
	const searchTimer = useRef(null);

	const abortController = new AbortController();
	const signal = abortController.signal;

	const handleSearchDevices = async () => {
		try {
			const response = await axiosPrivate.post(
				`searchDevices/${searchText}`,
				{},
				{ signal }
			);
			const data = response.data.data;
			const devWithDevType = data.map((dev) => {
				return {
					...dev,
					dev_type: dev?.metric_type
						? dev.metric_type.split(",")
						: [],
				};
			});
			if (devWithDevType.length) {
				setOutput((prev) => {
					return {
						...prev,
						["devices"]: devWithDevType,
					};
				});
			}
			setLoadingDev(false);
		} catch (error) {
			if (error?.name !== "CanceledError") {
				console.error("Error:", error);
			}
		}
	};

	const handleSearchKb = async () => {
		try {
			const response = await axiosPrivate.post(
				`searchKb/${i18n.language}/${searchText}`,
				{},
				{ signal }
			);
			const data = response.data.data;
			if (data.length) {
				setOutput((prev) => {
					return {
						...prev,
						["kb"]: data,
					};
				});
			}
			setLoadingKb(false);
		} catch (error) {
			if (error?.name !== "CanceledError") {
				console.error("Error:", error);
			}
		}
	};

	const delayedSearch = () => {
		clearTimeout(searchTimer.current);
		setOutput({});
		setLoadingDev(true);
		setLoadingKb(true);
		setLoaded(false);
		searchTimer.current = setTimeout(() => {
			Promise.all([handleSearchDevices(), handleSearchKb()])
				.then(() => {
					setLoaded(true);
				})
				.catch((error) => {
					// Handle errors if needed
					console.error("Error during search:", error);
					setLoaded(true);
				});
		}, 300);
	};

	useEffect(() => {
		if (searchText.length >= 3) {
			delayedSearch();
		} else {
			setOutput({});
			setLoadingDev(false);
			setLoadingKb(false);
			setLoaded(false);
			clearTimeout(searchTimer.current);
		}
		return () => {
			abortController.abort();
		};
	}, [searchText]);

	useEffect(() => {
		inputRef.current && inputRef.current.focus();
	}, []);

	return (
		<div className='p-2 fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-30'>
			<div className='rounded bg-white w-full h-full mx-auto p-4 pt-5 flex flex-col'>
				<div>
					<button
						className='btn btn-sm btn-circle btn-ghost text-black absolute top-3 right-3 shadow-inner border-gray-200'
						onClick={() => onClose()}
					>
						<CloseIcon />
					</button>
				</div>
				<div className='flex-1 overflow-y-auto'>
					<label>{t("search")}</label>
					<input
						ref={inputRef}
						onChange={(e) => {
							setSearchText(e.target.value);
						}}
						value={searchText}
						type='text'
						className='input input-bordered w-full mb-3 shadow-md'
					/>
					{searchText.length < 3 && (
						<p className='text-center mt-2 text-sm'>
							{t("minimalText")}
						</p>
					)}
					{!loadingDev && !loadingKb && loaded && Object.keys(output).length === 0 && (
						<p className='text-center mt-2 text-sm'>
							{t("noDataAvailable")}
						</p>
					)}
					{<DeviceSearch devices={output.devices} />}
					{<KbSearch kb={output.kb} />}
					{loadingDev && <DeviceSkeleton />}
					{loadingKb && <KbSkeleton />}
				</div>
			</div>
		</div>
	);
};

export default SearchModal;
