import { useTranslation } from "react-i18next";
import { CloseIcon } from "../Icons";

const DeleteImage = ({ onConfirm, onCancel }) => {
	const { t } = useTranslation();
	return (
		<div className='p-2 fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-30'>
			<div className='rounded bg-white p-4 flex flex-col'>
				<div>
					<button
						className='btn btn-sm btn-circle btn-ghost text-black shadow-inner border-gray-200'
						onClick={() => onCancel()}
					>
						<CloseIcon />
					</button>
				</div>
				<p className='mb-4'>{t("sureDelete")}</p>
				<div className='flex justify-end'>
					<button
						type='button'
						onClick={onCancel}
						className='bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 mr-2 rounded'
					>
						{t("no")}
					</button>
					<button
						type='button'
						onClick={onConfirm}
						className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded'
					>
						{t("yes")}
					</button>
				</div>
			</div>
		</div>
	);
};
export default DeleteImage;
