import { useEffect, useState } from "react";
import ChannelSelect from "../selects/ChannelSelect";
import { useTranslation } from "react-i18next";

const ChannelReassign = ({ metric, channels, setLines }) => {
	const { t } = useTranslation();

	const [selectedChannel, setSelectedChannel] = useState(-1);
	const [include, setInclude] = useState(false);

	useEffect(() => {
		let data = {};
		data.include = include;
		data.channel = selectedChannel;
		setLines(data);
	}, [include, selectedChannel]);

	return (
		<div>
			<div className='p-3 rounded-lg bg-white-200 border border-blue-500 rounded-full mb-3 shadow-md'>
				<div className=''>
					<div className='text-xl font-medium text-blue-500 w-full'>
						{metric.description}
					</div>
					<div className='w-full'>
						<label className='relative inline-flex items-center cursor-pointer'>
							<input
								type='checkbox'
								checked={include}
								onChange={() => setInclude(!include)}
								className='sr-only peer'
							/>
							<div className="w-11 h-6 bg-gray-500 rounded-full peer dark:bg-gray-500 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-500 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-500 peer-checked:bg-blue-500"></div>
							<span className='ml-3 text-sm'>{t("include")}</span>
						</label>
					</div>
					<div className='text-xs font-medium w-full'>
						<ChannelSelect
							onSelect={(channel) => setSelectedChannel(channel)}
							channelsArr={channels}
						/>
					</div>
					<div className='flex flex-col gap-2'>
						<div className='text-gray-500'></div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ChannelReassign;
