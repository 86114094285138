import { useEffect, useState } from "react";
import Select from "react-select";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useTranslation } from "react-i18next";

const CustomerSelect = ({
	onSelect,
	type = "",
	label = "customer",
	keepValue = true,
	clearSelection = false,
	required = false,
}) => {
	const axiosPrivate = useAxiosPrivate();

	const { t } = useTranslation();

	const [customers, setCustomers] = useState([]);
	const [selectedCustomer, setSelectedCustomer] = useState(
		keepValue ? localStorage.getItem(label + type) ?? -1 : -1
	);
	const [selectedCustomerName, setSelectedCustomerName] = useState("");

	const handleGetCustomers = async () => {
		try {
			const response = await axiosPrivate.post(`getCustomers`);
			const data = response.data.data;
			setCustomers(
				data.map((cust) => ({
					value: cust.id,
					label: cust.name,
				}))
			);
		} catch (error) {
			console.error("Error:", error);
		}
	};

	const handleSelectedCustomer = (selectedOption) => {
		if (keepValue) {
			localStorage.setItem(label + type, selectedOption.value);
			localStorage.setItem(label + "Name" + type, selectedOption.label);
		}
		setSelectedCustomer(selectedOption.value);
		setSelectedCustomerName(selectedOption.label);
	};

	useEffect(() => {
		handleGetCustomers();
	}, []);

	useEffect(() => {
		onSelect(selectedCustomer);
	}, [selectedCustomer]);

	useEffect(() => {
		if (clearSelection && !keepValue) {
			setSelectedCustomer(-1);
			setSelectedCustomerName("");
		}
	}, [clearSelection]);

	return (
		<div className='shadow-md'>
			<label>
				{t(label)} {required && <span className='text-red-600'>*</span>}
			</label>
			<Select
				options={customers}
				value={
					customers.find(
						(option) => option.value === selectedCustomer
					) || -1
				}
				onChange={handleSelectedCustomer}
				isSearchable
				placeholder={t("select")}
				className='mb-3'
			/>
		</div>
	);
};

export default CustomerSelect;
