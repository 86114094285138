import { useTranslation } from "react-i18next";
import { useState } from "react";
import Article from "../../kb/Article";

const KbSearch = ({ kb }) => {
	const { t } = useTranslation();

	const kbLoadNum = 2;

	const [displayedKb, setDisplayedKb] = useState(kbLoadNum);

	const loadMoreKb = () => {
		setDisplayedKb((prevCount) => prevCount + kbLoadNum);
	};

	const kbTypes = {
		2: t("instruction"),
		3: t("manual"),
	};

	return (
		kb &&
		kb.length > 0 && (
			<div className='kb mb-3'>
				<h2>{t("knowledgeBase")}</h2>
				{kb.slice(0, displayedKb).map((article) => (
					<Article
						key={`search-${article.identificator}`}
						article={article}
						articles={kb}
						type={kbTypes[article.type_id]}
					/>
				))}
				{kb.length > displayedKb && (
					<button
						type='button'
						className='bg-white border border-gray-500 hover:bg-gray-100 text-gray-500 hover:text-gray-700 text-center font-bold py-2 px-4 rounded w-full'
						onClick={loadMoreKb}
					>
						{t("loadMore")}
					</button>
				)}
			</div>
		)
	);
};
export default KbSearch;
