import { useState } from "react";
import DetailModal from "../device/DetailModal";

const DeviceLine = ({ line, lines, isLast, dev }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	const handleModal = (state) => {
		setIsModalOpen(state);
	};

	return (
		<div>
			<div
				onClick={() => handleModal(true)}
				className={`flex ${
					!isLast ? "border-b border-solid border-gray-500 py-2" : ""
				}`}
			>
				<div className='flex-1 pr-4'>
					<p className='font-bold'>{line.description}</p>
					<p className='text-xs'>
						{`${
							line.supply_point_id !== "1"
								? line.supply_point_name
								: ""
						} ${
							line.sp_description
								? `- ${line.sp_description}`
								: ""
						}`}
					</p>
				</div>
				<div className='flex-1 text-right'>
					<p className='font-bold'>{`${
						Math.round(line.value * 1000) / 1000
					} ${
						["7", "15"].includes(line.unit_id)
							? ""
							: line.unit_short_name
					}`}</p>
					<p className='text-xs'>
						{line.timestamp && new Date(line.timestamp * 1000).toLocaleString(
							"cs-CZ"
						)}
					</p>
				</div>
			</div>
			{isModalOpen && (
				<DetailModal
					line={line}
					lines={lines}
					dev={dev}
					onClose={() => handleModal(false)}
				/>
			)}
		</div>
	);
};

export default DeviceLine;
