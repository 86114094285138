import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
	CheckIcon,
	CircleMinusIcon,
	CommentsIcon,
	HourglassHalfIcon,
	TriangleExclamationIcon,
} from "../Icons";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import ExpandableText from "../../hooks/ExpandableText";
import InstalationModal from "../instalation/InstalationModal";
import ReassignModal from "../reassign/ReassignModal";

const WorkRow = ({
	wr,
	idx,
	handleDiary,
	swipedRowIndex,
	setSwipedRowIndex,
}) => {
	const { t } = useTranslation();
	const axiosPrivate = useAxiosPrivate();
	const navigate = useNavigate();

	const [isSwipedLeft, setIsSwipedLeft] = useState(false);
	const [isSwipedRight, setIsSwipedRight] = useState(false);
	const [status, setStatus] = useState(parseInt(wr.status_id));
	const [statusColor, setStatusColor] = useState(
		status === 1
			? "green"
			: status === 2
			? "gray"
			: status === 3
			? "blue"
			: status === 4
			? "red"
			: "black"
	);

	const [isReassignModalOpen, setIsReassignModalOpen] = useState(false);
	const [isInitModalOpen, setIsInitModalOpen] = useState(false);
	const [newDeviceData, setNewDeviceData] = useState([]);
	const [deviceLines, setDeviceLines] = useState({});
	const [hasPhotos, setHasPhotos] = useState();

	const [touchStartX, setTouchStartX] = useState(null);
	const [touchStartY, setTouchStartY] = useState(null);
	const [touchEndX, setTouchEndX] = useState(null);
	const [touchEndY, setTouchEndY] = useState(null);
	const minSwipeDistance = 50;

	const onTouchStart = (e) => {
		setTouchStartX(e.targetTouches[0].clientX);
		setTouchStartY(e.targetTouches[0].clientY);
		setTouchEndX(e.targetTouches[0].clientX);
		setTouchEndY(e.targetTouches[0].clientY);
	};

	const onTouchMove = (e) => {
		setTouchEndX(e.targetTouches[0].clientX);
		setTouchEndY(e.targetTouches[0].clientY);
	};

	const onTouchEnd = () => {
		const distanceX = touchStartX - touchEndX;
		const distanceY = touchStartY - touchEndY;
		const isLeftSwipe = distanceX > minSwipeDistance;
		const isRightSwipe = distanceX < -minSwipeDistance;
		if (
			(isLeftSwipe && distanceX > distanceY) ||
			(isRightSwipe && Math.abs(distanceX) > distanceY)
		) {
			closeText();
			if (isSwipedLeft) {
				setIsSwipedRight(false);
			} else {
				setIsSwipedRight(isRightSwipe);
			}
			if (isSwipedRight) {
				setIsSwipedLeft(false);
			} else {
				setIsSwipedLeft(isLeftSwipe);
			}
			setSwipedRowIndex(wr.id);
		} else {
			debouncedResetSwipe();
		}
	};

	const getTransform = () => {
		if (isSwipedLeft) {
			const elWidth = document.querySelector(".swipedLeftEl").clientWidth;
			return `translateX(-${elWidth}px)`;
		} else if (isSwipedRight) {
			const elWidth =
				document.querySelector(".swipedRightEl").clientWidth;
			return `translateX(${elWidth}px)`;
		}
		return "translateX(0px)";
	};

	const [isTextExpanded, setIsTextExpanded] = useState(false);

	const toggleText = () => {
		if (!isSwipedLeft && !isSwipedRight) {
			setIsTextExpanded(!isTextExpanded);
		}
	};

	const closeText = () => {
		setIsTextExpanded(false);
	};

	const debouncedResetSwipe = useCallback(
		debounce(() => {
			setIsSwipedLeft(false);
			setIsSwipedRight(false);
			setSwipedRowIndex(null);
		}, 300),
		[]
	);

	const resetSwipe = () => {
		setIsSwipedLeft(false);
		setIsSwipedRight(false);
	};

	const handleChangeStatus = async (st) => {
		try {
			const response = await axiosPrivate.post(
				`saveWorkRowStatus/${wr.id}/${st}/${wr.workorder_id}`
			);
			const data = response?.data?.data;
			setStatus(st);
			resetSwipe();
		} catch (error) {
			console.error("Error:", error);
		}
	};

	const handleGetDeviceLines = async (en) => {
		try {
			const response = await axiosPrivate.post(
				`deviceDetailByCode/` + wr.vhost_id + `/` + en
			);
			const data = response.data.data;
			setDeviceLines(data.lines);
			setNewDeviceData(data?.device_data[0] ?? []);
			setIsInitModalOpen(true);
		} catch (error) {
			console.error("Error:", error);
		}
	};

	const onEndpointClick = (en) => {
		handleGetDeviceLines(en);
	};

	const onFullPartClick = (wr) => {
		localStorage.setItem("customerReass", wr.customer_id);
		localStorage.setItem("customerNameReass", wr.customer_name);
		localStorage.setItem("legacyDeviceReass", wr.vhost_device_id1);
		localStorage.setItem("legacyDeviceNameReass", wr.device_name1);
		localStorage.setItem("newDeviceReass", wr.vhost_device_id2);
		localStorage.setItem("newDeviceNameReass", wr.device_name2);

		setIsReassignModalOpen(true);
	};

	const handleCloseReassignModal = () => {
		setIsReassignModalOpen(false);
		localStorage.removeItem("customerReass");
		localStorage.removeItem("customerNameReass");
		localStorage.removeItem("legacyDeviceReass");
		localStorage.removeItem("legacyDeviceNameReass");
		localStorage.removeItem("newDeviceReass");
		localStorage.removeItem("newDeviceNameReass");
	};

	const statuses = {
		1: { name: t("finish"), color: "green" },
		2: { name: t("notRel"), color: "black" },
		3: { name: t("pending"), color: "blue" },
		4: { name: t("fail"), color: "red" },
	};

	useEffect(() => {
		setStatusColor(status ? statuses[status].color : "gray");
	}, [status]);

	useEffect(() => {
		if (newDeviceData.device_code) {
			setHasPhotos(
				newDeviceData.has_image === "0"
					? 0
					: newDeviceData.has_image === "1" ||
					  newDeviceData.has_image !== newDeviceData.dev_lines
					? 1
					: 2
			);
		}
	}, [newDeviceData]);

	useEffect(() => {
		if (swipedRowIndex !== wr.id) {
			resetSwipe();
		}
	}, [swipedRowIndex]);

	return (
		<div>
			<div
				data-line_id={wr.id}
				data-rowi={idx + 1}
				className={`p-3 rounded-lg bg-white rounded-full mb-3 border border-${statusColor}-500 shadow-md relative overflow-hidden`}
			>
				<div
					onTouchStart={onTouchStart}
					onTouchMove={onTouchMove}
					onTouchEnd={onTouchEnd}
					style={{
						transform: getTransform(),
						transition: "transform 0.5s ease",
					}}
				>
					<div
						className={`text-xl font-medium text-${statusColor}-500`}
					>
						{idx + 1}
						{". "}
						{wr.work_type_name}
					</div>
					<div className='text-xs font-medium'>
						<div className='text-gray-500 mr-2'>
							{t("status")}
							{": "}
							<span className={`text-${statusColor}-500`}>
								{statuses[status]?.name ?? "-"}
							</span>
						</div>
						<div className='text-gray-500 mr-2'>
							<EndpointsPart
								wr={wr}
								onEndpointClick={(en) => onEndpointClick(en)}
								onFullPartClick={(wr) => onFullPartClick(wr)}
								isSwipedLeft={isSwipedLeft}
								isSwipedRight={isSwipedRight}
							/>
						</div>
						<ExpandableText
							text={wr.note}
							isExpanded={isTextExpanded}
							toggleText={toggleText}
						/>
					</div>
				</div>
				{/* Swipe left options */}
				<div
					className={`swipedLeftEl absolute right-0 top-0 bottom-0 flex transition-all duration-500 ${
						swipedRowIndex === wr.id && isSwipedLeft
							? "translate-x-0 opacity-100"
							: "translate-x-full opacity-0"
					}`}
				>
					<button
						onClick={() => {
							handleChangeStatus(1);
						}}
						className='flex flex-col items-center justify-center text-xs text-white bg-green-500 px-2 h-full w-16'
					>
						<CheckIcon />
						{t("finishSh")}
					</button>
					<button
						onClick={() => {
							handleChangeStatus(2);
						}}
						className='flex flex-col items-center justify-center text-xs text-white bg-gray-500 px-2 h-full w-16'
					>
						<CircleMinusIcon />
						{t("notRelSh")}
					</button>
					<button
						onClick={() => {
							handleChangeStatus(3);
						}}
						className='flex flex-col items-center justify-center text-xs text-white bg-blue-500 px-2 h-full w-16'
					>
						<HourglassHalfIcon />
						{t("pendingSh")}
					</button>
					<button
						onClick={() => {
							handleChangeStatus(4);
						}}
						className='flex flex-col items-center justify-center text-xs text-white bg-red-500 px-2 h-full w-16'
					>
						<TriangleExclamationIcon />
						{t("failSh")}
					</button>
				</div>
				{/* Swipe right options */}
				<div
					className={`swipedRightEl absolute left-0 top-0 bottom-0 transition-all duration-500 ${
						swipedRowIndex === wr.id && isSwipedRight
							? "translate-x-0 opacity-100"
							: "-translate-x-full opacity-0"
					}`}
				>
					<button
						onClick={() => {
							handleDiary(wr.id, idx + 1);
						}}
						className='flex flex-col items-center justify-center text-xs text-white bg-gray-500 px-3 h-full'
					>
						<CommentsIcon />
					</button>
				</div>

				{isInitModalOpen && newDeviceData && deviceLines && (
					<div>
						<InstalationModal
							dev={newDeviceData}
							lines={deviceLines}
							onClose={() => setIsInitModalOpen(false)}
							hasPhotos={hasPhotos}
							setHasPhotos={(val) => setHasPhotos(val)}
						/>
					</div>
				)}

				{isReassignModalOpen && (
					<div>
						<ReassignModal
							onClose={() => handleCloseReassignModal()}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

const EndpointsPart = ({
	wr,
	onEndpointClick,
	onFullPartClick,
	isSwipedLeft,
	isSwipedRight,
}) => {
	const { t } = useTranslation();
	let heading = t("devices");
	if (wr.device_select_type_1 === "3") {
		heading = t("connectors");
	}

	const handleEndpointClick = (en) => {
		if (isSwipedLeft || isSwipedRight) return;
		onEndpointClick(en);
	};

	const handleFullPartClick = (wr) => {
		if (isSwipedLeft || isSwipedRight) return;
		onFullPartClick(wr);
	};

	return (
		<div>
			{heading}:{" "}
			<span className='text-gray-500'>
				{wr.work_type_id === "2" ? (
					<ExchangePart
						wr={wr}
						onEndpointClick={handleEndpointClick}
						onFullPartClick={handleFullPartClick}
						isSwipedLeft={isSwipedLeft}
						isSwipedRight={isSwipedRight}
					/>
				) : wr.device_select_type_1 === "3" ? (
					<NotClickablePart wr={wr} />
				) : (
					<ClickablePart
						wr={wr}
						onEndpointClick={handleEndpointClick}
						onFullPartClick={handleFullPartClick}
						isSwipedLeft={isSwipedLeft}
						isSwipedRight={isSwipedRight}
					/>
				)}
			</span>
		</div>
	);
};

const ExchangePart = ({
	wr,
	onEndpointClick,
	onFullPartClick,
	isSwipedLeft,
	isSwipedRight,
}) => {
	return (
		<span
			onClick={() => onFullPartClick(wr)}
			className={!isSwipedLeft && !isSwipedRight ? "hover:underline" : ""}
		>
			{wr.device_name1 + " -> " + wr.device_name2}
		</span>
	);
};

const NotClickablePart = ({ wr }) => {
	return <span>{wr.con_name1}</span>;
};

const ClickablePart = ({
	wr,
	onEndpointClick,
	onFullPartClick,
	isSwipedLeft,
	isSwipedRight,
}) => {
	return (
		<span
			onClick={() => onEndpointClick(wr.device_id_1)}
			className={!isSwipedLeft && !isSwipedRight ? "hover:underline" : ""}
		>
			{wr.device_name1}
		</span>
	);
};

function debounce(func, delay) {
	let timer;
	return function (...args) {
		clearTimeout(timer);
		timer = setTimeout(() => {
			func.apply(this, args);
		}, delay);
	};
}

export default WorkRow;
