import { useEffect, useRef, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useTranslation } from "react-i18next";
import Chart from "react-apexcharts";
import { CloseIcon } from "../Icons";
import DataTableSkeleton from "../skeleton/DataTableSkeleton";
import NavigationButtons from "../layout/NavigationButtons";

const DetailModal = ({ line, lines, dev, onClose }) => {
	const axiosPrivate = useAxiosPrivate();

	const { t } = useTranslation();

	const modalRef = useRef();

	const [curLine, setCurLine] = useState(line);
	const [curLineIndex, setCurLineIndex] = useState(
		lines.findIndex((l) => l.id === line.id)
	);

	const [period, setPeriod] = useState("day");

	const [dataLength, setDataLength] = useState({});
	const [lineData, setLineData] = useState({});
	const [lineChartData, setLineChartData] = useState({});

	const [loading, setLoading] = useState(true);

	const showLine = (index) => {
		const newIndex = (index + lines.length) % lines.length;
		setCurLine(lines[newIndex]);
		setCurLineIndex(newIndex);
	};

	const handleSetDataLength = () => {
		setDataLength((previousData) => {
			return {
				...previousData,
				[curLine.id]: (previousData[curLine.id] || 5) + 5,
			};
		});
	};

	const handleLoadData = async () => {
		// if the dataLength equals the length of lineData array for current line
		if (lineData[curLine.id]?.length >= (dataLength[curLine.id] ?? 5)) {
			return;
		}
		try {
			setLoading(true);
			const response = await axiosPrivate.post(
				`getLineData?vhost_id=` +
					dev.vhost_id +
					`&dl_id=` +
					curLine.id +
					`&length=` +
					(dataLength[curLine.id] ?? "5")
			);
			const data = response?.data?.data;
			setLineData((previousData) => {
				return {
					...previousData,
					[curLine.id]: [
						...(previousData[curLine.id] || []), // Existing data (if any)
						...data,
					],
				};
			});
			setLoading(false);
		} catch (error) {
			console.error("Error:", error);
		}
	};

	const handlePeriodChange = (type) => {
		setPeriod(type);
	};

	const handleLoadDataChart = async () => {
		try {
			const response = await axiosPrivate.post(
				`getLineChartData?vhost_id=` +
					dev.vhost_id +
					`&dl_id=` +
					curLine.id +
					`&period=` +
					period
			);

			const data = response?.data?.data;

			// Transform data structure
			const transformedData = data.reduce(
				(acc, item) => {
					acc.timestamp.push(item.timestamp);
					acc.value.push(item.value);
					acc.unit.push(item.unit);
					return acc;
				},
				{ value: [], timestamp: [], unit: [] }
			);

			setLineChartData((previousData) => {
				return {
					...previousData,
					[curLine.id]: transformedData,
				};
			});
		} catch (error) {
			console.error("Error loading chart data:", error);
		}
	};

	useEffect(() => {
		handleLoadData();
	}, [curLine, dataLength]);

	useEffect(() => {
		handleLoadDataChart();
	}, [curLine, period]);

	return (
		<div className='p-2 fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-30'>
			<div
				ref={modalRef}
				className='rounded bg-white w-full h-full p-4 pt-5 flex flex-col'
			>
				<div className='first-part mb-2'>
					<button
						className='btn btn-sm btn-circle btn-ghost text-black absolute top-3 right-3 shadow-inner border-gray-200'
						onClick={() => onClose()}
					>
						<CloseIcon />
					</button>
					<p className='text-2xl text-blue-500 font-bold mb-2'>
						{dev.device_name}
					</p>
					<p className='text-lg font-bold mb-2'>
						{dev.device_description}
					</p>
					<div className='flex items-center mt-4 justify-between'>
						<p className='text-lg font-bold text-center truncate'>
							{`${curLine.description} ${
								curLine.supply_point_id !== "1"
									? `(` + curLine.supply_point_name + `)`
									: ""
							}`}
						</p>
						{lines?.length > 1 && (
							<NavigationButtons
								onClickPrev={() => showLine(curLineIndex - 1)}
								onClickNext={() => showLine(curLineIndex + 1)}
							/>
						)}
					</div>
				</div>
				<div className='scrollable-part flex-1 overflow-y-auto'>
					<div className='report-part'>
						<p className='text-lg'>{t("recentData")}</p>
						<DataTable
							key={"table"}
							t={t}
							data={lineData[curLine.id] ?? []}
							onClick={() => handleSetDataLength()}
							loading={loading}
						/>
					</div>
					<div className='chart-part w-full'>
						<div className='flex items-center mt-4 justify-between'>
							<p className='text-lg'>{t("lineChart")}</p>
							<ChartButtons
								onClick={(type) => handlePeriodChange(type)}
								period={period}
								t={t}
							/>
						</div>
						<DataChart
							key={"chart"}
							t={t}
							data={lineChartData[curLine.id] ?? []}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

const ChartButtons = ({ onClick, period, t }) => (
	<div className='chart-controls'>
		<button
			type='button'
			className={`${
				period === "day"
					? "bg-white border border-blue-500 hover:bg-blue-100 text-blue-500 hover:text-blue-700 text-center font-bold rounded py-2 px-4 shadow-md"
					: "bg-white border border-gray-500 hover:bg-gray-100 text-gray-500 hover:text-gray-700 text-center font-bold rounded py-2 px-4 shadow-md"
			}`}
			onClick={() => onClick("day")}
		>
			{t("24hrs")}
		</button>
		<button
			type='button'
			className={`ml-2 ${
				period === "week"
					? "bg-white border border-blue-500 hover:bg-blue-100 text-blue-500 hover:text-blue-700 text-center font-bold rounded py-2 px-4 shadow-md"
					: "bg-white border border-gray-500 hover:bg-gray-100 text-gray-500 hover:text-gray-700 text-center font-bold rounded py-2 px-4 shadow-md"
			}`}
			onClick={() => onClick("week")}
		>
			{t("7days")}
		</button>
	</div>
);

const DataTable = ({ data, t, onClick, loading }) => (
	<div className='overflow-x-auto'>
		<button
			onClick={() => onClick()}
			className='bg-white border border-gray-500 hover:bg-gray-100 text-gray-500 hover:text-gray-700 text-center font-bold py-2 px-4 rounded w-full shadow-md'
			disabled={data?.length === 0 || data?.length % 5 !== 0}
		>
			{loading
				? t("loading")
				: data?.length === 0
				? t("noDataAvailable")
				: data?.length % 5 !== 0
				? t("noMoreData")
				: t("loadMoreData")}
		</button>
		{loading && <DataTableSkeleton />}
		<table className='table'>
			<tbody>
				{data.map((row, index) => (
					<tr key={index}>
						<td>
							{new Date(row.timestamp * 1000).toLocaleString(
								"cs-CZ"
							)}
						</td>
						<td>
							{row.value} {row.unit}
						</td>
					</tr>
				))}
			</tbody>
		</table>
	</div>
);

const DataChart = ({ data, t }) =>
	data?.value?.length > 0 ? (
		<div>
			<Chart
				options={{
					chart: {
						type: "line",
					},
					xaxis: {
						type: "category",
						categories: data.timestamp,
						labels: {
							formatter: function (timestamp) {
								return new Date(
									timestamp * 1000
								).toLocaleString("cs-CZ");
							},
							rotate: 0,
						},
						tickAmount: 1,
						tooltip: {
							enabled: false,
						},
						tickPlacement: "between",
					},
					yaxis: {
						labels: {
							formatter: function (value) {
								return Math.round(value * 1000) / 1000; // Format as integers
							},
						},
					},
				}}
				series={[
					{
						name: t("value"),
						data: data.value,
					},
				]}
				height={350}
				type='line'
			/>
		</div>
	) : (
		<div className='text-center mt-2'>{t("noDataAvailable")}</div>
	);

export default DetailModal;
