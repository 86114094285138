import { useEffect, useState } from "react";
import Select from "react-select";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useTranslation } from "react-i18next";

const StatusSelect = ({
	onSelect,
	statusArr,
	type = "",
	label = "status",
	selected = -1,
	labelClass = "",
}) => {
	const axiosPrivate = useAxiosPrivate();

	const { t } = useTranslation();

	const [statuses, setStatuses] = useState([]);
	const [selectedStatus, setSelectedStatus] = useState(selected);
	const [selectedStatusName, setSelectedStatusName] = useState("");

	const handleSelectedStatus = (selectedOption) => {
		setSelectedStatus(selectedOption.value);
		setSelectedStatusName(selectedOption.label);
		onSelect(selectedOption.value);
	};

	useEffect(() => {
		setStatuses(
			statusArr.map((status) => ({
				value: status.id,
				label: status.name,
			}))
		);
	}, [statusArr]);

	return (
		<div className='shadow-md'>
			<label className={labelClass}>{t(label)}</label>
			<Select
				options={statuses}
				value={statuses.find(
					(option) => option.value == selectedStatus
				)}
				onChange={handleSelectedStatus}
				isSearchable
				placeholder={t("select")}
				className='mb-3'
			/>
		</div>
	);
};

export default StatusSelect;
