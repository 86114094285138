import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import LanguageSwitcher from "./LanguageSwitcher";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useAuth from "../../hooks/useAuth";
import { useJwt } from "react-jwt";
import { ChevronDoubleLeftIcon, SearchIcon } from "../Icons";
import { useEffect, useRef, useState } from "react";
import SearchModal from "../search/SearchModal";

const Header = ({ headerName, customName, homepage = false }) => {
	const { t } = useTranslation();
	const { auth, setAuth } = useAuth();
	const axiosPrivate = useAxiosPrivate();
	const { decodedToken } = useJwt(auth?.accessToken);

	const navigate = useNavigate();

	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [isSearchOpen, setIsSearchOpen] = useState(false);

	const handleLogout = async () => {
		try {
			await axiosPrivate.post(`/logout`);
		} catch (error) {
			console.error("Error:", error);
		}
		setAuth({});
		navigate("/login");
	};

	const initials = decodedToken?.user?.name
		.split(" ") // Split the name into an array of words
		.map((word) => word[0]) // Take the first character of each word
		.join(""); // Join the characters to form the initials

	const toggleDropdown = () => {
		setIsDropdownOpen((prevIsOpen) => !prevIsOpen);
	};

	const handleSearchModal = (state) => {
		setIsSearchOpen(state);
	};

	const dropdownRef = useRef(null);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				dropdownRef.current &&
				!dropdownRef.current.contains(event.target)
			) {
				setIsDropdownOpen(false);
			}
		};

		document.addEventListener("click", handleClickOutside);

		return () => {
			document.removeEventListener("click", handleClickOutside);
		};
	}, [dropdownRef]);

	useEffect(() => {
		const disableScroll = () => {
			document.body.classList.add("overflow-hidden");
		};
		const enableScroll = () => {
			document.body.classList.remove("overflow-hidden");
		};

		if (isSearchOpen) {
			disableScroll();
		} else {
			enableScroll();
		}

		return () => {
			enableScroll(); // Enable scroll when the component is unmounted
		};
	}, [isSearchOpen]);

	return (
		<div className='navbar mb-3 bg-base-100 shadow-md'>
			<div className='navbar-start text-blue-500'>
				{!homepage && (
					<div onClick={() => navigate(-1)}>
						<ChevronDoubleLeftIcon />
					</div>
				)}
			</div>
			<div
				className='navbar-center'
				style={{
					maxWidth: "65%",
				}}
			>
				<div
					onClick={() => navigate("/")}
					className='normal-case text-sm'
				>
					<p className={`text-center ${customName ? "" : "text-lg"}`}>
						{customName ? null : t(headerName ?? "appName")}
					</p>
					<p className={`text-center ${customName ? "text-lg" : ""}`}>
						{customName ?? ""}
					</p>
				</div>
			</div>
			<div className='navbar-end'>
				<button
					className='btn btn-ghost btn-circle'
					onClick={() => handleSearchModal(true)}
				>
					<SearchIcon />
				</button>
				<div
					className='dropdown dropdown-end'
					ref={dropdownRef}
					onClick={toggleDropdown}
				>
					<label
						tabIndex={0}
						className='btn btn-ghost btn-circle border-1 border-blue-500 shadow-md'
					>
						{initials}
					</label>
					{isDropdownOpen && (
						<ul
							tabIndex={0}
							className='mt-3 z-[1] p-2 shadow-lg menu menu-sm dropdown-content bg-base-100 rounded-box w-52 '
						>
							<LanguageSwitcher />
							<hr />
							<li>
								<button
									className='text-red-500'
									onClick={() => handleLogout()}
								>
									{t("logout")}
								</button>
							</li>
						</ul>
					)}
				</div>
			</div>
			{isSearchOpen && (
				<SearchModal onClose={() => handleSearchModal(false)} />
			)}
		</div>
	);
};
export default Header;
