const DeviceSkeleton = () => {
	const renderSkeletons = () => {
		const skeletons = [];
		for (let i = 0; i < 2; i++) {
			skeletons.push(
				<div key={i} className='skeleton-device'>
					<div className='p-3 rounded-lg bg-white-200 border border-gray-500 rounded-full mb-3'>
						<div className='flex items-center justify-between'>
							<div className='text-xl font-medium w-full'>
								<div className='loading-bar h-6 w-4/5 rounded mb-2'></div>
								<div className='loading-bar h-4 w-2/5 rounded'></div>
							</div>
							<div className='flex flex-col gap-2'>
								<div className='flex justify-center'>
									<div className='loading-bar h-6 w-6 rounded-full ml-2'></div>
									<div className='loading-bar h-6 w-6 rounded-full ml-2'></div>
								</div>
								<div className='flex justify-center'>
									<div className='loading-bar h-6 w-6 rounded-full ml-2'></div>
									<div className='loading-bar h-6 w-6 rounded-full ml-2'></div>
									<div className='loading-bar h-6 w-6 rounded-full ml-2'></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
		return skeletons;
	};

	return <div>{renderSkeletons()}</div>;
};

export default DeviceSkeleton;
