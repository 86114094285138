export const CameraIcon = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		fill='none'
		viewBox='0 0 24 24'
		strokeWidth={1.5}
		stroke='currentColor'
		className='w-6 h-6'
	>
		<path
			strokeLinecap='round'
			strokeLinejoin='round'
			d='M6.827 6.175A2.31 2.31 0 015.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 00-1.134-.175 2.31 2.31 0 01-1.64-1.055l-.822-1.316a2.192 2.192 0 00-1.736-1.039 48.774 48.774 0 00-5.232 0 2.192 2.192 0 00-1.736 1.039l-.821 1.316z'
		/>
		<path
			strokeLinecap='round'
			strokeLinejoin='round'
			d='M16.5 12.75a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM18.75 10.5h.008v.008h-.008V10.5z'
		/>
	</svg>
);

export const InfoIcon = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		fill='none'
		viewBox='0 0 24 24'
		strokeWidth={1.5}
		stroke='currentColor'
		className='w-6 h-6'
	>
		<path
			strokeLinecap='round'
			strokeLinejoin='round'
			d='M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z'
		/>
	</svg>
);

export const SignalIcon = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		fill='none'
		viewBox='0 0 24 24'
		strokeWidth={1.5}
		stroke='currentColor'
		className='w-6 h-6'
	>
		<path
			strokeLinecap='round'
			strokeLinejoin='round'
			d='M8.288 15.038a5.25 5.25 0 017.424 0M5.106 11.856c3.807-3.808 9.98-3.808 13.788 0M1.924 8.674c5.565-5.565 14.587-5.565 20.152 0M12.53 18.22l-.53.53-.53-.53a.75.75 0 011.06 0z'
		/>
	</svg>
);

export const TrashIcon = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		fill='none'
		viewBox='0 0 24 24'
		strokeWidth={1.5}
		stroke='currentColor'
		className='w-6 h-6'
	>
		<path
			strokeLinecap='round'
			strokeLinejoin='round'
			d='M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0'
		/>
	</svg>
);

export const PhotoIcon = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		fill='none'
		viewBox='0 0 24 24'
		strokeWidth={1.5}
		stroke='currentColor'
		className='w-6 h-6'
	>
		<path
			strokeLinecap='round'
			strokeLinejoin='round'
			d='M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z'
		/>
	</svg>
);

export const ChevronDoubleLeftIcon = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		fill='none'
		viewBox='0 0 24 24'
		strokeWidth={1.5}
		stroke='currentColor'
		className='w-6 h-6'
	>
		<path
			strokeLinecap='round'
			strokeLinejoin='round'
			d='M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5'
		/>
	</svg>
);

export const ChevronDoubleRightIcon = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		fill='none'
		viewBox='0 0 24 24'
		strokeWidth={1.5}
		stroke='currentColor'
		className='w-6 h-6'
	>
		<path
			strokeLinecap='round'
			strokeLinejoin='round'
			d='M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5'
		/>
	</svg>
);

export const CloseIcon = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		fill='none'
		viewBox='0 0 24 24'
		strokeWidth={1.5}
		stroke='currentColor'
		className='w-6 h-6'
	>
		<path
			strokeLinecap='round'
			strokeLinejoin='round'
			d='M6 18L18 6M6 6l12 12'
		/>
	</svg>
);

export const PlusIcon = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		fill='none'
		viewBox='0 0 24 24'
		strokeWidth={1.5}
		stroke='currentColor'
		className='w-6 h-6'
	>
		<path
			strokeLinecap='round'
			strokeLinejoin='round'
			d='M 4 12 L 20 12 M 12 4 l 0 16'
		/>
	</svg>
);

export const ElectroIcon = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		fill='none'
		viewBox='0 0 24 24'
		strokeWidth={1.5}
		stroke='currentColor'
		className='w-6 h-6'
	>
		<path
			strokeLinecap='round'
			strokeLinejoin='round'
			d='M14.69 2.21L4.33 11.49c-.64.58-.28 1.65.58 1.73L13 14l-4.85 6.76c-.22.31-.19.74.08 1.01.3.3.77.31 1.08.02l10.36-9.28c.64-.58.28-1.65-.58-1.73L11 10l4.85-6.76c.22-.31.19-.74-.08-1.01a.77.77 0 00-1.08-.02z'
		></path>
	</svg>
);

export const GasIcon = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		fill='none'
		viewBox='0 0 24 24'
		strokeWidth={1.5}
		stroke='currentColor'
		className='w-6 h-6'
	>
		<path
			strokeLinecap='round'
			strokeLinejoin='round'
			d='M 8.5348 1.9944 c 0.2808 -0.2628 0.7164 -0.2592 0.9972 0.0036 c 0.9936 0.9324 1.926 1.9368 2.7972 3.024 c 0.396 -0.5184 0.846 -1.0836 1.332 -1.5444 c 0.2844 -0.2664 0.7236 -0.2664 1.008 0.0036 c 1.2456 1.188 2.3004 2.7576 3.042 4.248 c 0.7308 1.4688 1.2168 2.97 1.2168 4.0284 C 18.928 16.3512 15.3352 20.232 10.864 20.232 C 6.3424 20.232 2.8 16.3476 2.8 11.754 c 0 -1.3824 0.6408 -3.0708 1.6344 -4.7412 C 5.4388 5.3172 6.8572 3.5496 8.5348 1.9944 z M 10.9252 16.776 c 0.9108 0 1.7172 -0.252 2.4768 -0.756 c 1.5156 -1.0584 1.9224 -3.1752 1.0116 -4.8384 c -0.162 -0.324 -0.576 -0.3456 -0.81 -0.072 l -0.9072 1.0548 c -0.2376 0.2736 -0.666 0.2664 -0.8892 -0.018 c -0.594 -0.756 -1.656 -2.106 -2.2608 -2.8728 c -0.2268 -0.288 -0.6588 -0.2916 -0.8892 -0.0036 c -1.2168 1.53 -1.8288 2.4948 -1.8288 3.5784 C 6.832 15.3144 8.6536 16.776 10.9252 16.776 z'
		></path>
	</svg>
);

export const WaterIcon = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		fill='none'
		viewBox='0 0 24 24'
		strokeWidth={1.5}
		stroke='currentColor'
		className='w-6 h-6'
	>
		<path
			strokeLinecap='round'
			strokeLinejoin='round'
			d='M 15.488 6.4416 c -1.5136 -2.1384 -2.97 -3.454 -3.9952 -4.2504 c -0.1364 -0.1056 -0.3212 -0.1056 -0.4576 -0.0044 c -1.012 0.7524 -2.4684 2.1296 -3.982 4.246 c -1.6412 2.288 -2.772 4.7696 -2.8248 7.5196 c 0 0.0528 -0.0044 0.11 -0.0044 0.1628 c 0 0.8096 0.1716 1.5796 0.4796 2.2924 c 0.1804 0.4092 0.4048 0.7964 0.6688 1.1572 c 1.254 1.716 3.4232 2.8512 5.8872 2.8512 c 3.8896 0 7.0444 -2.8204 7.0444 -6.3008 c 0 -2.8028 -1.188 -5.3768 -2.816 -7.6736 z m -3.784 11.6292 h -0.022 c -0.4356 0 -0.528 -0.6204 -0.1144 -0.7524 c 1.9844 -0.6248 3.0624 -1.694 3.8016 -3.5552 c 0.154 -0.3916 0.7348 -0.286 0.7392 0.1364 v 0.0616 c -0.0044 2.2704 -1.9756 4.1096 -4.4044 4.1096 z'
		></path>
	</svg>
);

export const AirIcon = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		fill='none'
		viewBox='0 0 24 24'
		strokeWidth={1.5}
		stroke='currentColor'
		className='w-6 h-6'
	>
		<path
			strokeLinecap='round'
			strokeLinejoin='round'
			d='M 14.05 15.3 c 0 1.485 -1.215 2.7 -2.7 2.7 s -2.7 -1.215 -2.7 -2.7 h 1.8 c 0 0.495 0.405 0.9 0.9 0.9 s 0.9 -0.405 0.9 -0.9 s -0.405 -0.9 -0.9 -0.9 H 2.8 v -1.8 h 8.55 c 1.485 0 2.7 1.215 2.7 2.7 z M 18.1 5.85 C 18.1 4.113 16.687 2.7 14.95 2.7 S 11.8 4.113 11.8 5.85 h 1.8 c 0 -0.747 0.603 -1.35 1.35 -1.35 s 1.35 0.603 1.35 1.35 S 15.697 7.2 14.95 7.2 H 2.8 v 1.8 h 12.15 c 1.737 0 3.15 -1.413 3.15 -3.15 z m -0.45 4.05 H 2.8 v 1.8 h 14.85 c 0.747 0 1.35 0.603 1.35 1.35 s -0.603 1.35 -1.35 1.35 v 1.8 c 1.737 0 3.15 -1.413 3.15 -3.15 S 19.387 9.9 17.65 9.9 z'
		></path>
	</svg>
);

export const HeatIcon = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		fill='none'
		viewBox='0 0 24 24'
		strokeWidth={1.5}
		stroke='currentColor'
		className='w-6 h-6'
	>
		<path
			strokeLinecap='round'
			strokeLinejoin='round'
			d='M 11.0423 13.9539 c 0 1.5856 0.0015 3.3279 -0.0007 4.7341 c -0.0007 0.4199 -0.1779 0.5934 -0.5956 0.6073 c -5.1358 0.0569 -6.5078 -5.738 -3.2572 -8.2625 c -1.4215 0.0117 -2.9299 0.0051 -4.3638 0.0051 c -0.9849 0 -1.0308 -0.0073 -1.0242 -0.9849 c 0.1881 -4.799 5.7926 -6.0325 8.2545 -2.8693 L 10.0435 2.4765 c -0.0007 -0.5067 0.1545 -0.6605 0.6605 -0.6743 c 4.6722 -0.1363 6.6776 5.3275 3.2266 8.2567 l 4.4695 0.0015 c 0.7997 0 0.9047 0.0765 0.8952 0.8872 c -0.1626 4.9011 -5.6877 6.1061 -8.253 3.0064 z m 0.0015 -11.142 l -0.0022 7.2696 c 4.7822 -0.339 4.8099 -6.9488 0.0022 -7.2696 z m -0.9914 15.4723 l -0.0007 -7.2681 c -5.0498 0.4359 -4.5854 7.0903 0.0015 7.2681 z M 2.8206 10.0486 l 7.2535 0.0058 c -0.3842 -4.9783 -7.1165 -4.6415 -7.2535 -0.0058 z m 8.1925 0.9951 c 0.4972 5.0119 7.0494 4.581 7.2732 0.0073 z'
		></path>
	</svg>
);

export const SteamIcon = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		fill='none'
		viewBox='0 0 24 24'
		strokeWidth={1.5}
		stroke='currentColor'
		className='w-6 h-6'
	>
		<path
			strokeLinecap='round'
			strokeLinejoin='round'
			d='M 3.34 10.48 c 0 -0.94 0.3 -1.78 0.89 -2.52 s 1.34 -1.21 2.25 -1.41 C 6.73 5.43 7.3 4.5 8.2 3.78 s 1.92 -1.08 3.08 -1.08 c 1.12 0 2.13 0.35 3.02 1.05 c 0.89 0.7 1.46 1.6 1.73 2.69 h 0.27 c 1.12 0 2.08 0.39 2.88 1.18 c 0.79 0.78 1.19 1.74 1.19 2.85 c 0 0.6 -0.12 1.17 -0.37 1.7 c -0.25 0.53 -0.59 0.99 -1.03 1.37 v 0.03 c 0 0.59 -0.19 1.12 -0.56 1.59 c -0.37 0.47 -0.84 0.76 -1.4 0.89 c -0.14 0.62 -0.45 1.15 -0.91 1.58 c -0.46 0.43 -1.01 0.7 -1.63 0.8 c 0.29 0.34 0.43 0.72 0.43 1.13 c 0 0.48 -0.17 0.89 -0.51 1.24 c -0.34 0.34 -0.75 0.52 -1.23 0.52 c -0.48 0 -0.89 -0.17 -1.23 -0.52 c -0.34 -0.34 -0.51 -0.76 -0.51 -1.24 c 0 -0.19 0.03 -0.38 0.1 -0.57 h -0.1 c -0.58 0 -1.08 -0.21 -1.5 -0.63 c -0.42 -0.42 -0.63 -0.92 -0.63 -1.5 c 0 -0.4 0.1 -0.76 0.3 -1.07 c -0.52 -0.29 -0.89 -0.7 -1.12 -1.25 h -1.28 v -0.01 c -1.07 -0.07 -1.98 -0.49 -2.73 -1.27 S 3.34 11.56 3.34 10.48 z M 4.74 10.23 c 0 0.8 0.28 1.48 0.84 2.04 s 1.24 0.84 2.03 0.84 c 0.49 0 0.95 -0.11 1.37 -0.34 c 0.12 0.74 0.47 1.36 1.04 1.86 s 1.25 0.74 2.02 0.74 c 0.87 0 1.61 -0.31 2.22 -0.92 c 0.41 0.48 0.92 0.71 1.54 0.71 c 0.57 0 1.05 -0.2 1.46 -0.6 c 0.4 -0.4 0.6 -0.89 0.6 -1.46 c 0.4 -0.27 0.72 -0.61 0.95 -1.04 c 0.23 -0.42 0.35 -0.88 0.35 -1.37 c 0 -0.79 -0.28 -1.47 -0.85 -2.02 c -0.57 -0.55 -1.25 -0.83 -2.05 -0.83 c -0.56 0 -1.07 0.15 -1.53 0.44 c 0.06 -0.24 0.08 -0.51 0.08 -0.79 c 0 -0.96 -0.34 -1.78 -1.03 -2.46 c -0.69 -0.68 -1.52 -1.01 -2.49 -1.01 c -0.94 0 -1.75 0.33 -2.43 0.97 s -1.04 1.44 -1.07 2.37 c -0.02 0 -0.05 0 -0.08 0 c -0.04 0 -0.07 0 -0.09 0 c -0.79 0 -1.46 0.28 -2.03 0.84 S 4.74 9.45 4.74 10.23 z'
		></path>
	</svg>
);

export const RefreshIcon = ({ isRefresh = false }) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		fill='none'
		viewBox='0 0 24 24'
		strokeWidth={1.5}
		stroke='currentColor'
		className={`w-6 h-6 ${isRefresh ? "rotate-360" : ""}`}
	>
		<path d='M3 12a9 9 0 0 1 9-9 9.75 9.75 0 0 1 6.74 2.74L21 8'></path>
		<path d='M21 3v5h-5'></path>
		<path d='M21 12a9 9 0 0 1-9 9 9.75 9.75 0 0 1-6.74-2.74L3 16'></path>
		<path d='M8 16H3v5'></path>
	</svg>
);

export const SearchIcon = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		className='h-5 w-5'
		fill='none'
		viewBox='0 0 24 24'
		stroke='currentColor'
	>
		<path
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth='2'
			d='M 19.5 19 l -6 -6 m 2 -5 a 7 7 0 1 1 -14 0 a 7 7 0 0 1 14 0 z'
		/>
	</svg>
);

export const ArticleIcon = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		className='h-5 w-5'
		fill='none'
		viewBox='0 0 20 20'
		stroke='currentColor'
	>
		<path d='M 1.5 2 m 0 2 a 2 2 0 0 1 2 -2 h 14 a 2 2 0 0 1 2 2 v 12 a 2 2 0 0 1 -2 2 h -14 a 2 2 0 0 1 -2 -2 z'></path>
		<path d='M 5.5 6 h 10'></path>
		<path d='M 5.5 10 h 10'></path>
		<path d='M 5.5 14 h 10'></path>
	</svg>
);

export const CheckIcon = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		className='h-5 w-5'
		fill='currentColor'
		viewBox='0 0 20 20'
		stroke='currentColor'
	>
		<path d='M 6.9081 16.9974 l -6.3232 -6.3232 c -0.3799 -0.3799 -0.3799 -0.9958 0 -1.3758 l 1.3757 -1.3758 c 0.3799 -0.3799 0.9959 -0.3799 1.3758 0 L 7.596 12.1822 L 16.7196 3.0586 c 0.3799 -0.3799 0.9959 -0.3799 1.3758 0 l 1.3757 1.3758 c 0.3799 0.3799 0.3799 0.9958 0 1.3758 l -11.1872 11.1872 c -0.3799 0.3799 -0.9959 0.3799 -1.3758 0 z'></path>
	</svg>
);

export const CircleMinusIcon = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		className='h-5 w-5'
		fill='currentColor'
		viewBox='0 0 20 20'
		stroke='currentColor'
	>
		<path d='M 10.0052 18.7604 A 8.7552 8.7552 90 1 0 10.0052 1.25 a 8.7552 8.7552 90 1 0 0 17.5104 z M 7.5428 9.1844 H 12.4676 c 0.4549 0 0.8208 0.3659 0.8208 0.8208 s -0.3659 0.8208 -0.8208 0.8208 H 7.5428 c -0.4549 0 -0.8208 -0.3659 -0.8208 -0.8208 s 0.3659 -0.8208 0.8208 -0.8208 z'></path>
	</svg>
);

export const HourglassHalfIcon = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		className='h-5 w-5 flex justify-center items-center'
		fill='currentColor'
		viewBox='0 0 20 20'
		stroke='currentColor'
	>
		<path d='M 15.936 1 H 4.1424 C 3.6772 1 3.3 1.3772 3.3 1.8424 v 0.5616 c 0 0.4652 0.3772 0.8424 0.8424 0.8424 c 0 3.1928 1.7906 5.8874 4.2415 6.7392 C 5.933 10.8374 4.1424 13.532 4.1424 16.7248 c -0.4652 0 -0.8424 0.3772 -0.8424 0.8424 v 0.5616 c 0 0.4652 0.3772 0.8424 0.8424 0.8424 h 11.7936 c 0.4652 0 0.8424 -0.3772 0.8424 -0.8424 v -0.5616 c 0 -0.4652 -0.3772 -0.8424 -0.8424 -0.8424 c 0 -3.1928 -1.7906 -5.8874 -4.2415 -6.7392 C 14.1454 9.1338 15.936 6.4392 15.936 3.2464 c 0.4652 0 0.8424 -0.3772 0.8424 -0.8424 V 1.8424 c 0 -0.4652 -0.3772 -0.8424 -0.8424 -0.8424 z m -2.6352 13.4784 H 6.7777 c 0.5988 -1.6426 1.8285 -2.808 3.2615 -2.808 c 1.4328 0 2.6627 1.1651 3.2616 2.808 z m 0.0006 -8.9856 H 6.7776 C 6.5287 4.8101 6.3888 4.0449 6.3888 3.2464 h 7.3008 c 0 0.8005 -0.1399 1.565 -0.3882 2.2464 z'></path>
	</svg>
);

export const TriangleExclamationIcon = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		className='h-5 w-5'
		fill='currentColor'
		viewBox='0 0 20 20'
		stroke='currentColor'
	>
		<path d='M 9.984 1.248 c 0.5538 0 1.0647 0.2925 1.3455 0.7722 l 8.424 14.352 c 0.2847 0.4836 0.2847 1.0803 0.0078 1.5639 S 18.9657 18.72 18.408 18.72 H 1.56 c -0.5577 0 -1.0764 -0.3003 -1.3533 -0.7839 s -0.273 -1.0842 0.0078 -1.5639 l 8.424 -14.352 C 8.9193 1.5405 9.4302 1.248 9.984 1.248 z m 0 4.992 c -0.5187 0 -0.936 0.4173 -0.936 0.936 V 11.544 c 0 0.5187 0.4173 0.936 0.936 0.936 s 0.936 -0.4173 0.936 -0.936 V 7.176 c 0 -0.5187 -0.4173 -0.936 -0.936 -0.936 z m 1.248 8.736 a 1.248 1.248 90 1 0 -2.496 0 a 1.248 1.248 90 1 0 2.496 0 z'></path>
	</svg>
);

export const CommentsIcon = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		className='h-5 w-5'
		fill='currentColor'
		viewBox='0 0 20 20'
		stroke='currentColor'
	>
		<path d='M 14.104 8.048 c 0 -2.7846 -2.9327 -5.04 -6.552 -5.04 S 1 5.2634 1 8.048 c 0 1.0804 0.4441 2.0759 1.197 2.898 c -0.4221 0.9513 -1.1182 1.7073 -1.1277 1.7167 c -0.0693 0.0725 -0.0882 0.1796 -0.0473 0.2741 S 1.1512 13.088 1.252 13.088 c 1.1529 0 2.1073 -0.3875 2.794 -0.7875 c 1.0143 0.4945 2.2145 0.7875 3.506 0.7875 c 3.6193 0 6.552 -2.2554 6.552 -5.04 z m 3.843 6.93 c 0.7529 -0.819 1.197 -1.8175 1.197 -2.898 c 0 -2.1073 -1.6853 -3.9123 -4.073 -4.6652 c 0.0284 0.2079 0.041 0.419 0.041 0.6331 c 0 3.3359 -3.3925 6.048 -7.56 6.048 c -0.3402 0 -0.671 -0.0252 -0.9985 -0.0599 C 7.5457 15.8474 9.8767 17.12 12.592 17.12 c 1.2915 0 2.4916 -0.2898 3.506 -0.7875 c 0.6867 0.4001 1.6411 0.7875 2.794 0.7875 c 0.1008 0 0.1921 -0.0599 0.23 -0.1512 c 0.041 -0.0914 0.0221 -0.1985 -0.0473 -0.2741 c -0.0095 -0.0095 -0.7056 -0.7623 -1.1277 -1.7167 z'></path>
	</svg>
);

export const ImageIcon = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		className='h-5 w-5'
		fill='currentColor'
		viewBox='0 0 20 20'
		stroke='currentColor'
	>
		<path d='M 0.8 4.206 C 0.8 2.9352 1.8332 1.902 3.104 1.902 H 16.928 c 1.2708 0 2.304 1.0332 2.304 2.304 V 15.75 c 0 1.2708 -1.0332 2.304 -2.304 2.304 H 3.104 c -1.2708 0 -2.304 -1.0332 -2.304 -2.304 V 4.206 z M 12.4568 8.04 c -0.162 -0.2376 -0.4284 -0.378 -0.7128 -0.378 s -0.5544 0.1404 -0.7128 0.378 l -3.132 4.5936 L 6.9452 11.442 c -0.1656 -0.2052 -0.414 -0.324 -0.6732 -0.324 s -0.5112 0.1188 -0.6732 0.324 l -2.304 2.88 c -0.2088 0.2592 -0.2484 0.6156 -0.1044 0.9144 s 0.4464 0.4896 0.7776 0.4896 h 3.456 h 1.152 H 16.064 c 0.3204 0 0.6156 -0.1764 0.7632 -0.4608 s 0.1296 -0.6264 -0.0504 -0.8892 l -4.32 -6.336 z M 4.832 7.662 a 1.728 1.728 90 1 0 0 -3.456 a 1.728 1.728 90 1 0 0 3.456 z'></path>
	</svg>
);

export const PaperPlaneIcon = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		className='h-5 w-5'
		fill='currentColor'
		viewBox='0 0 20 20'
		stroke='currentColor'
	>
		<path d='M 18.4335 1.196 c 0.3535 0.245 0.539 0.6685 0.4725 1.092 l -2.24 14.56 c -0.0525 0.3395 -0.259 0.637 -0.56 0.805 s -0.6615 0.189 -0.98 0.056 L 10.94 15.9695 l -2.3975 2.5935 c -0.3115 0.3395 -0.8015 0.4515 -1.232 0.2835 S 6.6 18.262 6.6 17.8 V 14.874 c 0 -0.14 0.0525 -0.273 0.147 -0.3745 L 12.613 8.098 c 0.203 -0.2205 0.196 -0.56 -0.014 -0.77 s -0.5495 -0.224 -0.77 -0.0245 L 4.71 13.628 L 1.6195 12.081 C 1.2485 11.8955 1.0105 11.5245 1 11.1115 s 0.2065 -0.798 0.5635 -1.0045 l 15.68 -8.96 c 0.3745 -0.2135 0.8365 -0.1925 1.19 0.049 z'></path>
	</svg>
);
