import { useEffect, useRef, useState } from "react";
import { CloseIcon, CommentsIcon, PlusIcon } from "../Icons";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import OrderSkeleton from "../skeleton/OrderSkeleton";
import WorkRow from "./WorkRow";
import DiaryModal from "../diary/DiaryModal";
import NavigationButtons from "../layout/NavigationButtons";
import { useTranslation } from "react-i18next";
import StatusSelect from "../selects/StatusSelect";
import NewWorkRowModal from "./NewWorkRowModal";

const OrderModal = ({ order, orders, onClose, statuses, setStatus }) => {
	const { t } = useTranslation();
	const modalRef = useRef();
	const axiosPrivate = useAxiosPrivate();

	const [curOrder, setCurOrder] = useState(order);
	const [curOrderIndex, setCurOrderIndex] = useState(
		orders.findIndex((a) => a.id === order.id)
	);

	const [orderDetail, setOrderDetail] = useState([]);
	const [loading, setLoading] = useState(true);

	const [diaryData, setDiaryData] = useState([]);
	const [isDiaryModalOpen, setIsDiaryModalOpen] = useState(false);
	const [diaryRefId, setDiaryRefId] = useState(0);
	const [diaryRefWrId, setDiaryRefWrId] = useState(0);

	const [startTime, setStartTime] = useState("");
	const [endTime, setEndTime] = useState("");
	const [driveDistance, setDriveDistance] = useState(
		order.real_transport ?? "0"
	);

	const [swipedRowIndex, setSwipedRowIndex] = useState(null);

	const [selectedStatus, setSelectedStatus] = useState(curOrder.status_id);

	const abortControllerRef = useRef(null);

	const [isNewWorkRowModalOpen, setIsNewWorkRowModalOpen] = useState(false);

	const handleGetWo = async () => {
		setLoading(true);
		setOrderDetail([]);
		try {
			const response = await axiosPrivate.post(
				`getWoDetail/${curOrder.id}`
			);
			const data = response?.data?.data;
			setOrderDetail(data);
			setLoading(false);
		} catch (error) {
			console.error("Error:", error);
		}
	};

	const showOrder = (index) => {
		setOrderDetail([]);
		const newIndex = (index + orders.length) % orders.length;
		setCurOrder(orders[newIndex]);
		setCurOrderIndex(newIndex);
	};

	const handleGetDiaryData = async (id) => {
		try {
			const response = await axiosPrivate.post(`getWoDiary/${id}`);
			const data = response?.data?.data;
			setDiaryData(data);
		} catch (error) {
			console.error("Error:", error);
		}
	};

	const handleDiary = async (task_id, index_id) => {
		setDiaryRefId(index_id);
		setDiaryRefWrId(task_id);
		await handleGetDiaryData(curOrder.id);
		setIsDiaryModalOpen(true);
	};

	const handleCloseDiarynModal = () => {
		setIsDiaryModalOpen(false);
	};

	const handleStartEndWork = async (type, date) => {
		if (abortControllerRef.current) {
			abortControllerRef.current.abort();
		}
		abortControllerRef.current = new AbortController();
		const timestamp = new Date(date).getTime() / 1000;
		try {
			await axiosPrivate.post(
				`${type}/${curOrder.id}/${timestamp}`,
				{},
				{ signal: abortControllerRef.current.signal }
			);
		} catch (error) {
			if (error.name !== "AbortError") {
				console.error("Error:", error);
			}
		}
	};

	const handleDriveDistance = async () => {
		if (abortControllerRef.current) {
			abortControllerRef.current.abort();
		}
		abortControllerRef.current = new AbortController();
		try {
			if (driveDistance) {
				await axiosPrivate.post(
					`saveDriveDistance/${curOrder.id}/${driveDistance}`,
					{},
					{ signal: abortControllerRef.current.signal }
				);
			}
		} catch (error) {
			if (error.name !== "AbortError") {
				console.error("Error:", error);
			}
		}
	};

	const formatDateToLocalISO = (timestamp) => {
		const date = new Date(timestamp * 1000);
		const off = date.getTimezoneOffset();
		return new Date(date.getTime() - off * 60 * 1000)
			.toISOString()
			.slice(0, 19);
	};

	const handleSaveStatus = async (st) => {
		try {
			const response = await axiosPrivate.post(
				`saveWorkOrderStatus/${curOrder.id}/${st}`
			);
			const data = response?.data?.data;
			setSelectedStatus(st);
			setStatus(st);
		} catch (error) {
			console.error("Error:", error);
		}
	};

	const handleSaveNewWorkRow = () => {
		handleGetWo();
		setIsNewWorkRowModalOpen(false);
		return () => {
			if (abortControllerRef.current) {
				abortControllerRef.current.abort();
			}
		};
	};

	useEffect(() => {
		if (curOrder.real_work_time) {
			const startDate = formatDateToLocalISO(curOrder.real_work_time);
			setStartTime(startDate);
		} else {
			setStartTime("");
		}
		if (curOrder.real_work_time_end) {
			const endDate = formatDateToLocalISO(curOrder.real_work_time_end);
			setEndTime(endDate);
		} else {
			setEndTime("");
		}
	}, [curOrder]);

	useEffect(() => {
		handleGetWo();
		return () => {
			if (abortControllerRef.current) {
				abortControllerRef.current.abort();
			}
		};
	}, [curOrder]);

	useEffect(() => {
		handleDriveDistance();
	}, [driveDistance]);

	return (
		<div className='p-2 fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-30'>
			<div
				ref={modalRef}
				className='rounded bg-white w-full h-full p-4 pt-5 flex flex-col'
			>
				<div className='first-part mb-2'>
					<button
						className='btn btn-sm btn-circle btn-ghost text-black absolute top-3 right-3 shadow-inner border-gray-200'
						onClick={() => onClose()}
					>
						<CloseIcon />
					</button>
				</div>
				<div className='scrollable-part flex-1 overflow-y-auto overflow-x-hidden'>
					<p className='text-2xl text-blue-500 font-bold mb-2'>
						{curOrder.site_text}
					</p>
					<div className='mb-2'>
						<button
							onClick={() => {
								handleDiary(0);
							}}
							className='bg-white border border-gray-500 hover:bg-gray-100 text-gray-500 hover:text-gray-700 text-center font-bold py-2 px-4 rounded-full'
						>
							<CommentsIcon />
						</button>
					</div>
					<div className='mx-1'>
						<StatusSelect
							onSelect={(status) => {
								handleSaveStatus(status);
							}}
							selected={selectedStatus}
							statusArr={statuses}
							labelClass={
								"block text-gray-700 text-sm font-bold mb-1"
							}
						/>
					</div>
					<div className='mb-2 flex flex-wrap'>
						<div className='mb-1 mx-1'>
							<label
								htmlFor='driveDistance'
								className='block text-gray-700 text-sm font-bold mb-1'
							>
								{t("driveDistance")}
							</label>
							<input
								onChange={(e) => {
									setDriveDistance(e.target.value);
								}}
								onFocus={(e) => {
									if (parseFloat(e.target.value ?? 0) === 0) {
										e.target.select();
									}
								}}
								onBlur={(e) => {
									if (e.target.value.trim() === "") {
										setDriveDistance("0");
									}
								}}
								value={driveDistance}
								type='number'
								onWheel={(e) => e.target.blur()}
								className='input input-bordered w-full mb-3'
								min={0}
								id='driveDistance'
							/>
						</div>
						<div className='mb-1 mx-1'>
							<label
								htmlFor='realworkstart'
								className='block text-gray-700 text-sm font-bold mb-1'
							>
								{t("realWorkStart")}
							</label>
							<input
								type='datetime-local'
								id='realworkstart'
								className='input input-bordered rounded'
								value={startTime}
								onChange={(e) => {
									setStartTime(e.target.value);
									handleStartEndWork(
										"saveWorkOrderRealStart",
										e.target.value
									);
								}}
							/>
						</div>
						<div className='mb-1 mx-1'>
							<label
								htmlFor='realworkend'
								className='block text-gray-700 text-sm font-bold mb-1'
							>
								{t("realWorkEnd")}
							</label>
							<input
								type='datetime-local'
								id='realworkend'
								className='input input-bordered rounded'
								value={endTime}
								onChange={(e) => {
									setEndTime(e.target.value);
									handleStartEndWork(
										"saveWorkOrderRealEnd",
										e.target.value
									);
								}}
							/>
						</div>
					</div>
					<div className='flex justify-end mb-2'>
						<button
							className='btn btn-sm btn-circle btn-ghost text-black shadow-inner border-gray-200'
							onClick={() => setIsNewWorkRowModalOpen(true)}
						>
							<PlusIcon />
						</button>
					</div>
					{loading && <OrderSkeleton />}
					{orderDetail.map((od, idx) => {
						return (
							<WorkRow
								key={od.id}
								wr={od}
								idx={idx}
								handleDiary={(task_id, index_id) =>
									handleDiary(task_id, index_id)
								}
								swipedRowIndex={swipedRowIndex}
								setSwipedRowIndex={setSwipedRowIndex}
							/>
						);
					})}
				</div>
				{isDiaryModalOpen && (
					<div>
						<DiaryModal
							key={crypto.randomUUID()}
							wo={curOrder}
							diaryData={diaryData}
							diaryRefId={diaryRefId}
							diaryRefWrId={diaryRefWrId}
							onClose={() => handleCloseDiarynModal()}
							handleLoadDiary={() =>
								handleGetDiaryData(curOrder.id)
							}
						/>
					</div>
				)}
				{isNewWorkRowModalOpen && (
					<NewWorkRowModal
						onClose={() => setIsNewWorkRowModalOpen(false)}
						onSave={handleSaveNewWorkRow}
						wo={curOrder}
					/>
				)}
				{true && (
					<div className='flex items-center mt-4'>
						{orders?.length > 1 && (
							<NavigationButtons
								onClickPrev={() => showOrder(curOrderIndex - 1)}
								onClickNext={() => showOrder(curOrderIndex + 1)}
								addedStyle='justify-between w-full'
							/>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

export default OrderModal;
