import { useEffect, useState } from "react";
import Header from "../components/layout/Header";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import Device from "../components/instalation/Device";
import { useTranslation } from "react-i18next";
import { RefreshIcon } from "../components/Icons";
import DeviceSkeleton from "../components/skeleton/DeviceSkeleton";

const InstalationLocation = () => {
	const axiosPrivate = useAxiosPrivate();

	const { t } = useTranslation();

	const locationId = localStorage.getItem("location");
	const locationName = localStorage.getItem("locationName");
	const customerId = localStorage.getItem("customer");
	const customerName = localStorage.getItem("customerName");
	const customName =
		customerName && customerName !== ""
			? customerName + " - " + locationName
			: locationName;

	const [devices, setDevices] = useState([]);

	const [connectors, setConnectors] = useState([]);
	const [selectedConnectors, setSelectedConnectors] = useState([]);

	const [isRefresh, setIsRefresh] = useState(false);

	const [loading, setLoading] = useState(true);

	const typeFilter = [
		{ id: 1, name: t("electro") },
		{ id: 2, name: t("gas") },
		{ id: 3, name: t("water") },
		{ id: 4, name: t("air") },
		{ id: 5, name: t("heat") },
		{ id: 6, name: t("steam") },
	];
	const [selectedTypes, setSelectedTypes] = useState([]);

	const genFilter = [
		// { id: 1, name: t("all") },
		{ id: 2, name: t("needAction") },
		{ id: 3, name: t("noInit") },
		{ id: 4, name: t("notConn") },
	];
	const [selectedGenFilt, setSelectedGenFilt] = useState([]);

	const handleGetDevices = async () => {
		try {
			const response = await axiosPrivate.post(
				`getDevices?customer_id=` +
					customerId +
					`&location_id=` +
					locationId
			);
			const data = response?.data?.data;
			const devWithDevType = data.map((dev) => {
				return {
					...dev,
					dev_type: dev?.metric_type
						? dev.metric_type.split(",")
						: [],
				};
			});
			setConnectors((prevConnectors) => {
				const newConnectors = devWithDevType.map((el) => el.connector);
				const uniqueConnectors = [
					...new Set([...prevConnectors, ...newConnectors]),
				];
				return uniqueConnectors;
			});
			setDevices(devWithDevType);
			setLoading(false);
		} catch (error) {
			console.error("Error:", error);
		}
	};

	const handleCheckboxChange = (connId) => {
		setSelectedConnectors((prevSelectedIConns) => {
			if (prevSelectedIConns.includes(connId)) {
				// If the item is already in the array, remove it
				return prevSelectedIConns.filter((item) => item !== connId);
			} else {
				// If the item is not in the array, add it
				return [...prevSelectedIConns, connId];
			}
		});
	};

	const handleTypeCheckboxChange = (typeId) => {
		setSelectedTypes((prevSelectedTypes) => {
			if (prevSelectedTypes.includes(typeId)) {
				// If the item is already in the array, remove it
				return prevSelectedTypes.filter((item) => item !== typeId);
			} else {
				// If the item is not in the array, add it
				return [...prevSelectedTypes, typeId];
			}
		});
	};

	const handleGenCheckboxChange = (genId) => {
		setSelectedGenFilt((prevSelectedGen) => {
			if (prevSelectedGen.includes(genId)) {
				// If the item is already in the array, remove it
				return prevSelectedGen.filter((item) => item !== genId);
			} else {
				// If the item is not in the array, add it
				return [...prevSelectedGen, genId];
			}
		});
	};

	const handleRefreshData = async () => {
		if (!isRefresh) {
			setIsRefresh(true);
			await handleGetDevices();
			setTimeout(() => {
				setIsRefresh(false);
			}, 1000);
		}
	};

	useEffect(() => {
		handleGetDevices();
	}, []);

	return (
		<div>
			<Header headerName={"instalation"} customName={customName} />
			<div className='mx-3 mt-5'>
				<div className='types flex overflow-x-auto scrollbar-hidden'>
					{typeFilter.map((type) => (
						<label
							key={type["id"]}
							className={`mr-2 mb-2 inline-flex items-center cursor-pointer bg-white border text-center font-bold py-1 px-3 rounded-lg whitespace-nowrap shadow-md ${
								selectedTypes.includes(type["id"])
									? "border-blue-500 text-blue-500"
									: "border-gray-500 text-gray-500"
							}`}
						>
							<input
								type='checkbox'
								className='hidden'
								checked={selectedTypes.includes(type["id"])}
								onChange={() =>
									handleTypeCheckboxChange(type["id"])
								}
							/>
							<span>{type["name"] ? type["name"] : "N/A"}</span>
						</label>
					))}
				</div>
				<div className='connectors flex overflow-x-auto scrollbar-hidden'>
					{connectors.map((conn) => (
						<label
							key={conn}
							className={`mr-2 mb-2 inline-flex items-center cursor-pointer bg-white border text-center font-bold py-1 px-3 rounded-lg whitespace-nowrap shadow-md ${
								selectedConnectors.includes(conn)
									? "border-blue-500 text-blue-500"
									: "border-gray-500 text-gray-500"
							}`}
						>
							<input
								type='checkbox'
								className='hidden'
								checked={selectedConnectors.includes(conn)}
								onChange={() => handleCheckboxChange(conn)}
							/>
							<span>{conn ? conn : "N/A"}</span>
						</label>
					))}
				</div>
				<div className='genFil flex overflow-x-auto scrollbar-hidden'>
					{genFilter.map((gen) => (
						<label
							key={gen["id"]}
							className={`mr-2 mb-2 inline-flex items-center cursor-pointer bg-white border text-center font-bold py-1 px-3 rounded-lg whitespace-nowrap shadow-md ${
								selectedGenFilt.includes(gen["id"])
									? "border-blue-500 text-blue-500"
									: "border-gray-500 text-gray-500"
							}`}
						>
							<input
								type='checkbox'
								className='hidden'
								checked={selectedGenFilt.includes(gen["id"])}
								onChange={() =>
									handleGenCheckboxChange(gen["id"])
								}
							/>
							<span>{gen["name"] ? gen["name"] : "N/A"}</span>
						</label>
					))}
				</div>
				<div className='refresh mb-2 flex justify-end'>
					<button
						type='button'
						className='flex'
						onClick={() => handleRefreshData()}
					>
						<div className='flex'>
							<RefreshIcon isRefresh={isRefresh} />
						</div>
						<p className='ml-1'>{t("refresh")}</p>
					</button>
				</div>
				<div className='devices'>
					{loading && <DeviceSkeleton />}
					{devices.map((dev) => {
						return (
							(selectedConnectors.length === 0 ||
								selectedConnectors.includes(dev.connector)) &&
							(selectedTypes.length === 0 ||
								dev.dev_type.some((type) =>
									selectedTypes.includes(parseFloat(type))
								)) && (
								<Device
									key={dev.device_code}
									dev={dev}
									genFilter={selectedGenFilt}
									isRefresh={isRefresh}
								/>
							)
						);
					})}
				</div>
			</div>
		</div>
	);
};
export default InstalationLocation;
