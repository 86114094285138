import { useEffect, useRef, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import DeviceLine from "./DeviceLine";
import InstalationModal from "./InstalationModal";
import { useTranslation } from "react-i18next";
import {
	AirIcon,
	ArticleIcon,
	CameraIcon,
	ElectroIcon,
	GasIcon,
	HeatIcon,
	InfoIcon,
	SignalIcon,
	SteamIcon,
	WaterIcon,
} from "../Icons";
import DeviceLineSkeleton from "../skeleton/DeviceLineSkeleton";
import KbModal from "../kb/KbModal";

const Device = ({ dev, genFilter = [], isRefresh = false }) => {
	const axiosPrivate = useAxiosPrivate();

	const { t } = useTranslation();
	const timeNow = Date.now();
	const dayBack = Math.floor(timeNow / 1000) - 86400;

	const devType = dev.dev_type;
	const [hasPhotos, setHasPhotos] = useState(
		dev.has_image === "0"
			? 0
			: dev.has_image === "1" || dev.has_image !== dev.dev_lines
			? 1
			: 2
	);
	const hasData =
		dev.data_count === "0" && !dev.last_val_time
			? 0
			: dev.data_count > 1
			? 2
			: 1;
	const quality =
		dev.last_val_time < dayBack
			? 0
			: dev.rssi_value && dev.rssi_value > -100
			? 2
			: 1;

	const initialized =
		dev.need_init === "0" && hasPhotos === 2
			? 2
			: (dev.need_init !== dev.dev_lines && hasPhotos >= 1) ||
			  hasPhotos === 2 ||
			  hasPhotos === 1 ||
			  hasData >= 1
			? 1
			: 0;

	const [isOpen, setIsOpen] = useState(false);
	const [deviceLines, setDeviceLines] = useState({});

	const [isInitModalOpen, setIsInitModalOpen] = useState(false);
	const [isArticleModalOpen, setIsArticleModalOpen] = useState(false);

	const [loading, setLoading] = useState(true);

	const initRef = useRef();
	const articleRef = useRef();

	const handleGetDeviceLines = async (dev) => {
		try {
			const response = await axiosPrivate.post(
				`deviceDetail/` + dev.vhost_id + `/` + dev.id
			);
			const data = response.data.data;
			setDeviceLines(data);
			setLoading(false);
		} catch (error) {
			console.error("Error:", error);
		}
	};

	const handleOpen = async (dev, e) => {
		const curTrg = initRef.current && initRef.current.contains(e.target);
		const curTrgArt =
			articleRef.current && articleRef.current.contains(e.target);
		if (!curTrg && !curTrgArt) {
			setIsOpen(!isOpen);
		}
		await handleGetDeviceLines(dev, e);
		if (curTrg) {
			setIsInitModalOpen(!isInitModalOpen);
			return;
		}
		if (curTrgArt) {
			setIsArticleModalOpen(!isArticleModalOpen);
			return;
		}
	};

	useEffect(() => {
		if (isRefresh) {
			handleGetDeviceLines(dev);
		}
	}, [isRefresh]);

	useEffect(() => {
		const disableScroll = () => {
			document.body.classList.add("overflow-hidden");
		};
		const enableScroll = () => {
			document.body.classList.remove("overflow-hidden");
		};

		if (isInitModalOpen || isArticleModalOpen) {
			disableScroll();
		} else {
			enableScroll();
		}

		return () => {
			enableScroll(); // Enable scroll when the component is unmounted
		};
	}, [isInitModalOpen, isArticleModalOpen]);

	// if there is general filter show only devices that meet the filter
	if (
		!(
			genFilter.length === 0 ||
			(genFilter.includes(2) &&
				(hasData === 0 || quality === 0 || initialized === 0)) ||
			(genFilter.includes(3) &&
				(initialized === 0 || initialized === 1)) ||
			(genFilter.includes(4) && (hasData === 0 || quality === 0))
		)
	) {
		return null;
	}

	return (
		<div className='rounded-lg bg-white-200 border border-blue-500 rounded-full mb-3 shadow-md'>
			<div
				className={`px-3 pt-3 ${isOpen ? "" : "pb-3"}`}
				onClick={(e) => handleOpen(dev, e)}
			>
				<div className='flex items-center justify-between'>
					<div className='text-xl font-medium'>
						<div className='flex'>
							<p className='text-blue-500'>{dev.device_name}</p>
							{dev.has_article > 0 && (
								<span
									className='ml-1 flex items-center text-gray-500'
									ref={articleRef}
								>
									<ArticleIcon />
								</span>
							)}
						</div>
						<p className='text-xs'>
							{dev.connector ? dev.connector : "N/A"}
						</p>
					</div>
					<Icons
						initRef={initRef}
						devType={devType}
						hasPhotos={hasPhotos}
						initialized={initialized}
						hasData={hasData}
						quality={quality}
					/>
				</div>
			</div>
			{isOpen && (
				<div className='mx-3 mb-3'>
					{loading && <DeviceLineSkeleton />}
					{deviceLines && deviceLines.length > 0
						? deviceLines.map((line, index, array) => (
								<DeviceLine
									key={line.id}
									line={line}
									lines={array}
									dev={dev}
									isLast={index === array.length - 1}
								/>
						  ))
						: !loading && (
								<p className='text-center mt-2'>
									{t("noDeviceLines")}
								</p>
						  )}
				</div>
			)}

			{isInitModalOpen && (
				<div>
					<InstalationModal
						dev={dev}
						lines={deviceLines}
						onClose={() => setIsInitModalOpen(false)}
						hasPhotos={hasPhotos}
						setHasPhotos={(val) => setHasPhotos(val)}
					/>
				</div>
			)}

			{isArticleModalOpen && (
				<div>
					<KbModal
						dev={dev}
						onClose={() => setIsArticleModalOpen(false)}
						articleModal={isArticleModalOpen}
					/>
				</div>
			)}
		</div>
	);
};

const Icons = ({
	initRef,
	devType,
	hasPhotos,
	initialized,
	hasData,
	quality,
}) => (
	<div className='flex flex-col gap-2'>
		<div className='flex justify-center'>
			<span
				className={`ml-2 ${
					hasPhotos === 0
						? `text-red-500`
						: hasPhotos === 1
						? `text-yellow-500`
						: `text-green-500`
				}`}
			>
				<CameraIcon />
			</span>
			<span className='ml-2 text-blue-500 flex gap-1'>
				{devType.map(
					(type) =>
						(type === "1" && <ElectroIcon key={type} />) ||
						(type === "2" && <GasIcon key={type} />) ||
						(type === "3" && <WaterIcon key={type} />) ||
						(type === "4" && <AirIcon key={type} />) ||
						(type === "5" && <HeatIcon key={type} />) ||
						(type === "6" && <SteamIcon key={type} />)
				)}
			</span>
		</div>
		<div className='flex justify-center'>
			<span
				className={`ml-2 ${
					initialized === 0
						? `text-red-500`
						: initialized === 1
						? `text-yellow-500`
						: `text-green-500`
				}`}
				ref={initRef}
			>
				<InfoIcon />
			</span>
			<span
				className={`ml-2 ${
					hasData === 2
						? `text-green-500`
						: hasData === 1
						? `text-yellow-500`
						: `text-red-500`
				}`}
			>
				DATA
			</span>
			<span
				className={`ml-2 ${
					quality === 2
						? `text-green-500`
						: quality === 1
						? `text-yellow-500`
						: `text-red-500`
				}`}
			>
				<SignalIcon />
			</span>
		</div>
	</div>
);

export default Device;
