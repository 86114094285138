import { useEffect, useRef, useState } from "react";
import Header from "../components/layout/Header";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useTranslation } from "react-i18next";
import WorkOrderSkeleton from "../components/skeleton/WorkOrderSkeleton";
import Order from "../components/workorder/Order";

const WorkOrder = () => {
	const { t, i18n } = useTranslation();
	const axiosPrivate = useAxiosPrivate();

	const [alertMessage, setAlertMessage] = useState("");
	const [alertType, setAlertType] = useState("success");
	const [showAlert, setShowAlert] = useState(false);

	const handleSetAlert = (message, type) => {
		setAlertMessage(message);
		setAlertType(type);
		setShowAlert(true);
		setTimeout(() => {
			setShowAlert(false);
		}, 2000);
	};

	const [workOrder, setWorkOrder] = useState([]);
	const [selectedTypes, setSelectedTypes] = useState([]);
	const [loading, setLoading] = useState(true);

	const [vhosts, setVhosts] = useState([]);
	const [selectedVhosts, setSelectedVhosts] = useState([]);

	const periodFilter = [
		{ id: "1", name: t("today") },
		{ id: "2", name: t("tomorrow") },
		{ id: "3", name: t("thisWeek") },
		{ id: "4", name: t("nextWeek") },
	];

	const handleGetOrders = async () => {
		try {
			const response = await axiosPrivate.post(
				`getWorkOrders?filters=` + selectedTypes
			);
			const data = response?.data?.data;
			setWorkOrder(data);
			setLoading(false);
			setVhosts((prevVhosts) => {
				const newVhosts = data.map((el) => el.vhost_name);
				const uniqueVhosts = [
					...new Set([...prevVhosts, ...newVhosts]),
				];
				return uniqueVhosts;
			});
		} catch (error) {
			console.error("Error:", error);
		}
	};

	const handleTypeCheckboxChange = (typeId) => {
		setSelectedTypes((prevSelectedTypes) => {
			if (prevSelectedTypes.includes(typeId)) {
				// If the item is already in the array, remove it
				return prevSelectedTypes.filter((item) => item !== typeId);
			} else {
				// If the item is not in the array, add it
				return [...prevSelectedTypes, typeId];
			}
		});
	};

	const handleCheckboxChange = (vhost) => {
		setSelectedVhosts((prevSelectedVhosts) => {
			if (prevSelectedVhosts.includes(vhost)) {
				// If the item is already in the array, remove it
				return prevSelectedVhosts.filter((item) => item !== vhost);
			} else {
				// If the item is not in the array, add it
				return [...prevSelectedVhosts, vhost];
			}
		});
	};

	useEffect(() => {
		setLoading(true);
		handleGetOrders();
	}, [t, selectedTypes]);

	return (
		<div>
			<Header headerName={"workOrder"} />
			<div className='mx-3 mt-5'>
				<div className='types flex overflow-x-auto scrollbar-hidden'>
					{periodFilter.map((period) => (
						<label
							key={period["id"]}
							className={`mr-2 mb-3 inline-flex items-center cursor-pointer bg-white border text-center font-bold py-1 px-3 rounded-lg whitespace-nowrap shadow-md ${
								selectedTypes.includes(period["id"])
									? "border-blue-500 text-blue-500"
									: "border-gray-500 text-gray-500"
							}`}
						>
							<input
								type='checkbox'
								className='hidden'
								checked={selectedTypes.includes(period["id"])}
								onChange={() =>
									handleTypeCheckboxChange(period["id"])
								}
							/>
							<span>
								{period["name"] ? period["name"] : "N/A"}
							</span>
						</label>
					))}
				</div>

				<div className='vhosts flex overflow-x-auto scrollbar-hidden'>
					{vhosts.map((vhost) => (
						<label
							key={vhost}
							className={`mr-2 mb-2 inline-flex items-center cursor-pointer bg-white border text-center font-bold py-1 px-3 rounded-lg whitespace-nowrap shadow-md ${
								selectedVhosts.includes(vhost)
									? "border-blue-500 text-blue-500"
									: "border-gray-500 text-gray-500"
							}`}
						>
							<input
								type='checkbox'
								className='hidden'
								checked={selectedVhosts.includes(vhost)}
								onChange={() => handleCheckboxChange(vhost)}
							/>
							<span>{vhost ? vhost : "N/A"}</span>
						</label>
					))}
				</div>
				<div className='orders'>
					{loading && <WorkOrderSkeleton />}
					{!loading &&
						workOrder.map((wo) => {
							return (
								(selectedVhosts.length === 0 ||
									selectedVhosts.includes(wo.vhost_name)) && (
									<Order
										key={wo.id}
										wo={wo}
										wos={workOrder}
									/>
								)
							);
						})}
					{!loading && workOrder.length === 0 && (
						<p className='text-center mt-2 text-sm'>
							{t("noDataAvailable")}
						</p>
					)}
				</div>
			</div>
		</div>
	);
};
export default WorkOrder;
