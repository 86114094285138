import { useTranslation } from "react-i18next";
import { CloseIcon } from "../Icons";
import { useState } from "react";

const SWIPE_THRESHOLD = 50; // Adjust this value based on your preference for swipe sensitivity

const ImageModal = ({ allImages, selectedImage, onClose }) => {
	const { t } = useTranslation();

	const [curImg, setCurImg] = useState(allImages[selectedImage]);
	const [curImgIndex, setCurImgIndex] = useState(selectedImage);

	const [startX, setStartX] = useState(null);

	const handleTouchStart = (e) => {
		setStartX(e.touches[0].clientX);
	};

	const handleTouchMove = (e) => {
		if (startX === null) {
			return;
		}

		const deltaX = e.touches[0].clientX - startX;

		if (deltaX > SWIPE_THRESHOLD) {
			showLine(curImgIndex - 1); // Swipe right
			setStartX(null);
		} else if (deltaX < -SWIPE_THRESHOLD) {
			showLine(curImgIndex + 1); // Swipe left
			setStartX(null);
		}
	};

	const handleTouchEnd = () => {
		setStartX(null);
	};

	const showLine = (index) => {
		const newIndex = (index + allImages.length) % allImages.length;
		setCurImg(allImages[newIndex]);
		setCurImgIndex(newIndex);
	};

	return (
		<div
			className='fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-30'
			onTouchStart={handleTouchStart}
			onTouchMove={handleTouchMove}
			onTouchEnd={handleTouchEnd}
		>
			<div className='bg-white p-1 w-full h-full mx-auto flex flex-col'>
				<div>
					<button
						className='btn btn-sm btn-circle btn-ghost text-black absolute top-1 right-1 shadow-inner border-gray-200'
						onClick={() => onClose()}
					>
						<CloseIcon />
					</button>
				</div>
				<div className='modal-content m-auto'>
					<img
						src={curImg}
						alt='Full-size'
					/>
				</div>
			</div>
		</div>
	);
};

export default ImageModal;
