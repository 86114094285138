import { createContext, useState } from "react";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
	const [auth, setAuth] = useState({});
	const [isLogin, setIsLogin] = useState(false);

	return (
		<AuthContext.Provider value={{ auth, setAuth, isLogin, setIsLogin }}>
			{children}
		</AuthContext.Provider>
	);
};

export default AuthContext;
