import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enTranslation from "../locales/en.json";
import csTranslation from "../locales/cs.json";

const savedLanguage = localStorage.getItem("language") ?? "en";
i18n.use(initReactI18next).init({
	resources: {
		en: {
			translation: enTranslation,
		},
		cs: {
			translation: csTranslation,
		},
	},
	lng: savedLanguage, // default language
	fallbackLng: "en", // fallback language
	interpolation: {
		escapeValue: false,
	},
});

export default i18n;
