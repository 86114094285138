import axios from "axios";
var BASE_URL = "";

if (process.env.NODE_ENV === "development") {
	BASE_URL = "http://localhost:8080/handy/";
} else {
	BASE_URL = "https://client.iotor.io/handy/";
}

export default axios.create({
	baseURL: BASE_URL,
	withCredentials: true,
});

export const axiosPrivate = axios.create({
	baseURL: BASE_URL,
	headers: {
		"Content-Type": "multipart/form-data",
		"Cache-Control": "no-cache",
	},
	withCredentials: true,
});
