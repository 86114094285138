// ExpandableText.jsx
import React, { useRef, useEffect, useState } from "react";

const ExpandableText = ({ text, isExpanded, toggleText }) => {
	const [isTruncated, setIsTruncated] = useState(false);
	const textRef = useRef();

	useEffect(() => {
		const element = textRef.current;
		if (element) {
			setIsTruncated(element.scrollWidth > element.clientWidth);
		}
	}, [text]);

	return (
		<div className='relative'>
			<div
				onClick={isTruncated ? toggleText : null}
				ref={textRef}
				className={`text-gray-500 mt-2 ${
					!isExpanded ? "truncate" : ""
				} max-w-xs`}
				aria-expanded={isExpanded}
			>
				{text}
			</div>
			{isTruncated && !isExpanded && (
				<button
					className='text-gray-500 absolute right-0 top-0 ml-2'
					aria-label='Show more'
				>
					▼
				</button>
			)}
			{isExpanded && (
				<button
					className='text-gray-500 absolute right-0 top-0 ml-2'
					aria-label='Show less'
				>
					▲
				</button>
			)}
		</div>
	);
};

export default ExpandableText;
