import { useEffect, useRef, useState } from "react";
import { CameraIcon, PhotoIcon } from "../Icons";

const WebcamComponent = ({
	capturedImagesData,
	setCapturedImagesData,
	capturedImagesUrl,
	setCapturedImagesUrl,
	setNeedPhoto,
	setNewPhotoAdded,
	videoRef,
}) => {
	const canvasRef = useRef(null);

	const fileInputRef = useRef();

	const [disabledButtons, setDisabledButtons] = useState(true); // disable buttons for capture/upload photo until video started

	const constraints = {
		audio: false,
		video: {
			facingMode: "enviroment",
		},
	};
	const constraints_user = {
		audio: false,
		video: {
			facingMode: "user",
		},
	};

	const capturePhoto = (e) => {
		e.preventDefault();
		const canvas = canvasRef.current;
		const video = videoRef.current;

		if (canvas && video) {
			canvas.width = video.videoWidth;
			canvas.height = video.videoHeight;
			canvas
				.getContext("2d")
				.drawImage(video, 0, 0, canvas.width, canvas.height);
			canvas.toBlob((blob) => {
				blob.name = Math.random().toString(36).substring(2, 15);
				const imageUrl = canvas.toDataURL("image/png");
				setCapturedImagesData((prevData) => [blob, ...prevData]);
				setCapturedImagesUrl((prevData) => [imageUrl, ...prevData]);
			}, "image/png");
			setNeedPhoto(false);
			setNewPhotoAdded(true);
		}
	};

	const handleFileUpload = (e) => {
		const files = e.target.files;
		for (let i = 0; i < files.length; i++) {
			const file = files[i];
			const reader = new FileReader();

			reader.onloadend = function (e) {
				let src = "";
				let name = file.name;
				switch (file.type) {
					case "image/png":
					case "image/jpeg":
					case "image/jpg":
						src = e.target.result;
						break;
					case "application/pdf":
						src = "/public/assets/img/pdf.png";
						break;
					case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
						src = "/public/assets/img/word.png";
						break;
					default:
						src = "/public/assets/img/unsupported.png";
						break;
				}
				const blob = new Blob([file], {
					type: file.type,
					name: name,
				});
				setCapturedImagesData((prevData) => [blob, ...prevData]);
				setCapturedImagesUrl((prevData) => [src, ...prevData]);
			};

			reader.readAsDataURL(file);
			setNeedPhoto(false);
			setNewPhotoAdded(true);
		}
	};

	const handleMediaStream = (localMediaStream) => {
		if (videoRef.current) {
			if ("srcObject" in videoRef.current) {
				videoRef.current.srcObject = localMediaStream;
			} else {
				videoRef.current.src = URL.createObjectURL(localMediaStream);
			}
			videoRef.current.play();
			canvasRef.current.scrollIntoView({
				behavior: "smooth",
				block: "center",
				inline: "end",
			});
		}
	};

	useEffect(() => {
		const setupWebcam = async () => {
			await navigator.mediaDevices
				.getUserMedia(constraints)
				.then((localMediaStream) => {
					handleMediaStream(localMediaStream);
					setDisabledButtons(false);
				})
				.catch(() => {
					navigator.mediaDevices
						.getUserMedia(constraints_user)
						.then((localMediaStream) => {
							handleMediaStream(localMediaStream);
							setDisabledButtons(false);
						})
						.catch((err) => {
							console.error("Error accessing camera:", err);
						});
				});
		};

		setupWebcam();
	}, []);

	return (
		<div className=''>
			<div className='w-5/5 mx-auto rounded mb-2 flex flex-col'>
				<video
					className='rounded-t-lg'
					ref={videoRef}
					autoPlay
					muted
					playsInline
				></video>
				<div className='flex'>
					<button
						type='button'
						className='bg-white border border-gray-500 hover:bg-gray-100 text-gray-500 hover:text-gray-700 text-center font-bold py-2 px-4 w-full rounded-bl-lg'
						onClick={capturePhoto}
						disabled={disabledButtons}
					>
						<div className='flex items-center justify-center'>
							<CameraIcon />
						</div>
					</button>
					<div className='bg-white border border-gray-500 hover:bg-gray-100 text-gray-500 hover:text-gray-700 text-center font-bold py-2 px-4 w-full rounded-br-lg'>
						<label htmlFor='file-upload' className='cursor-pointer'>
							<input
								type='file'
								id='file-upload'
								ref={fileInputRef}
								onChange={handleFileUpload}
								multiple
								className='hidden'
								disabled={disabledButtons}
							/>
							<div className='flex items-center justify-center'>
								<PhotoIcon />
							</div>
						</label>
					</div>
				</div>
			</div>
			<canvas ref={canvasRef} style={{ display: "none" }}></canvas>
		</div>
	);
};

export default WebcamComponent;
