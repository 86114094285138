import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../components/layout/Header";
import CustomerSelect from "../components/selects/CustomerSelect";
import LocationSelect from "../components/selects/LocationSelect";
import { useTranslation } from "react-i18next";

const Instalation = () => {
	const navigate = useNavigate();

	const { t } = useTranslation();

	const [selectedCustomer, setSelectedCustomer] = useState(-1);
	const [selectedLocation, setSelectedLocation] = useState(-1);

	const handleRedirect = () => {
		navigate(`/instalation/location`);
	};

	useEffect(() => {
		if (selectedCustomer !== -1 && selectedLocation !== -1) {
			// navigate(`/instalation/location`);
		}
	}, [selectedCustomer, selectedLocation, navigate]);
	return (
		<div>
			<Header headerName={"instalation"} />
			<div className='mx-3 mt-5'>
				<CustomerSelect
					onSelect={(cust) => setSelectedCustomer(cust)}
				/>
				<LocationSelect
					selectedCustomer={selectedCustomer}
					onSelect={(loc) => setSelectedLocation(loc)}
				/>
				<button
					onClick={() => handleRedirect()}
					type='button'
					className='float-right bg-white border border-blue-500 hover:bg-blue-100 text-blue-500 hover:text-blue-700 text-center font-bold py-2 px-4 rounded'
				>
					{t("next")}
				</button>
			</div>
		</div>
	);
};
export default Instalation;
