import { useEffect, useState } from "react";
import Select from "react-select";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useTranslation } from "react-i18next";

const DeviceSelectTabs = ({
	selectedCustomer,
	onSelect,
	onSelectTab,
	type = "",
	label = "device",
	keepValue = true,
	required = false,
	labelClass = "",
	useCodeAsValue = false,
}) => {
	const axiosPrivate = useAxiosPrivate();
	const { t } = useTranslation();

	const [devices, setDevices] = useState([]);
	const [devicesWare, setDevicesWare] = useState([]);
	const [devicesTypes, setDevicesTypes] = useState([]);
	const [selectedDevice, setSelectedDevice] = useState(
		keepValue ? localStorage.getItem(label + type) ?? -1 : -1
	);
	const [selectedDeviceName, setSelectedDeviceName] = useState("");
	const [activeTab, setActiveTab] = useState("pill-real");
	const [activeTabIndex, setActiveTabIndex] = useState(1);

	const handleSelectedDevice = (selectedOption) => {
		if (keepValue) {
			localStorage.setItem(label + type, selectedOption.value);
			localStorage.setItem(label + "Name" + type, selectedOption.label);
		}
		setSelectedDevice(selectedOption.value);
		setSelectedDeviceName(selectedOption.label);
	};

	useEffect(() => {
		setDevices([]);
		setDevicesWare([]);
		setDevicesTypes([]);
		if (!keepValue) {
			setSelectedDevice(-1);
			onSelect(-1);
		}
		const handleGetDevices = async () => {
			try {
				if (selectedCustomer > 0) {
					const response = await axiosPrivate.post(
						`getCustomerDevicesTabs/${selectedCustomer}`
					);
					const data = response.data.data;
					console.log(data);
					setDevices(data.devices);
					setDevicesWare(data.warehouse_devices);
					setDevicesTypes(data.device_types);
				}
			} catch (error) {
				console.error("Error:", error);
			}
		};
		if (selectedCustomer !== -1) {
			handleGetDevices();
		}
	}, [selectedCustomer, axiosPrivate]);

	useEffect(() => {
		onSelect(selectedDevice);
	}, [selectedDevice]);

	useEffect(() => {
		onSelectTab(activeTabIndex);
	}, [activeTabIndex]);

	// Convert devices data to react-select options format
	const selectOptions = devices.map((dev) => ({
		value: useCodeAsValue ? dev.device_id : dev.vhost_device_id,
		label: dev.device_name,
	}));
	const selectOptionsWare = devicesWare.map((dev) => ({
		value: dev.device_id,
		label: dev.name,
	}));
	const selectOptionsTypes = devicesTypes.map((dev) => ({
		value: dev.id,
		label: dev.name,
	}));

	const handleTabClick = (tab, tab_index) => {
		setActiveTab(tab);
		setActiveTabIndex(tab_index);
		setSelectedDevice(-1);
	};

	return (
		<div className='shadow-md'>
			<label className={labelClass}>
				{t(label)} {required && <span className='text-red-600'>*</span>}
			</label>
			<div className="flex overflow-x-scroll scroll-hide">
				<div 
					className={`pill-menu-item ${activeTab === 'pill-real' ? 'pill-menu-item-active' : ''}`} 
					onClick={() => handleTabClick('pill-real', 1)}
				>
					<i className="fa-solid fa-walkie-talkie"></i> {t("onsite")}
				</div>
				<div 
					className={`pill-menu-item ${activeTab === 'pill-stock' ? 'pill-menu-item-active' : ''}`} 
					onClick={() => handleTabClick('pill-stock', 2)}
				>
					<i className="fa-solid fa-boxes-stacked"></i> {t("stock")}
				</div>
				<div 
					className={`pill-menu-item ${activeTab === 'pill-deviceType' ? 'pill-menu-item-active' : ''}`} 
					onClick={() => handleTabClick('pill-deviceType', 3)}
				>
					<i className="fa-solid fa-layer-group"></i> {t("deviceType")}
				</div>
			</div>
			<div className="pill-menu-container">
				<div className={`pill-menu ${activeTab === 'pill-real' ? '' : 'hidden'}`}>
					{selectOptions.length > 0 ? (
						<Select
							options={selectOptions}
							value={
								selectOptions.find(
									(option) => option.value === selectedDevice
								) || -1
							}
							onChange={handleSelectedDevice}
							isSearchable
							placeholder={t("select")}
							className='mb-3'
						/>
					) : (
						<Select placeholder={t("select")} className='mb-3' value={-1} />
					)}
				</div>
				<div className={`pill-menu ${activeTab === 'pill-stock' ? '' : 'hidden'}`}>
					{selectOptionsWare.length > 0 ? (
						<Select
							options={selectOptionsWare}
							value={
								selectOptionsWare.find(
									(option) => option.value === selectedDevice
								) || -1
							}
							onChange={handleSelectedDevice}
							isSearchable
							placeholder={t("select")}
							className='mb-3'
						/>
					) : (
						<Select placeholder={t("select")} className='mb-3' value={-1} />
					)}
				</div>
				<div className={`pill-menu ${activeTab === 'pill-deviceType' ? '' : 'hidden'}`}>
					{selectOptionsTypes.length > 0 ? (
						<Select
							options={selectOptionsTypes}
							value={
								selectOptionsTypes.find(
									(option) => option.value === selectedDevice
								) || -1
							}
							onChange={handleSelectedDevice}
							isSearchable
							placeholder={t("select")}
							className='mb-3'
						/>
					) : (
						<Select placeholder={t("select")} className='mb-3' value={-1} />
					)}
				</div>
			</div>
		</div>
	);
};

export default DeviceSelectTabs;
