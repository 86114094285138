import { useEffect, useState } from "react";
import Select from "react-select";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useTranslation } from "react-i18next";

const TaskTypeSelect = ({
	onSelect,
	taskTypeArr,
	type = "",
	label = "taskType",
	selected = -1,
	required = false,
	labelClass = "",
}) => {
	const axiosPrivate = useAxiosPrivate();

	const { t } = useTranslation();

	const [taskTypes, setTaskTypes] = useState([]);
	const [selectedTaskType, setSelectedTaskType] = useState(selected);
	const [selectedTaskTypeName, setSelectedTaskTypeName] = useState("");

	const handleSelectedTaskType = (selectedOption) => {
		setSelectedTaskType(selectedOption.value);
		setSelectedTaskTypeName(selectedOption.label);
		onSelect(selectedOption.value);
	};

	useEffect(() => {
		setTaskTypes(
			taskTypeArr.map((taskType) => ({
				value: taskType.id,
				label: taskType.name,
			}))
		);
	}, [taskTypeArr]);

	return (
		<div className='shadow-md'>
			<label className={labelClass}>
				{t(label)} {required && <span className='text-red-600'>*</span>}
			</label>
			<Select
				options={taskTypes}
				value={taskTypes.find(
					(option) => option.value == selectedTaskType
				)}
				onChange={handleSelectedTaskType}
				isSearchable
				placeholder={t("select")}
				className='mb-3'
			/>
		</div>
	);
};

export default TaskTypeSelect;
