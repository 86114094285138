import { useRef, useState } from "react";
import { CloseIcon } from "../Icons";
import Reassign from "./Reassign";
import { useTranslation } from "react-i18next";

const ReassignModal = ({ onClose }) => {
	const { t } = useTranslation();
	const modalRef = useRef();

	const closeModal = () => {
		onClose();
	};

	return (
		<div className='p-2 fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-30'>
			<div
				ref={modalRef}
				className='rounded bg-white w-full h-full mx-auto p-4 pt-5 flex flex-col'
			>
				<div>
					<button
						className='btn btn-sm btn-circle btn-ghost text-black absolute top-3 right-3 shadow-inner border-gray-200'
						onClick={() => closeModal()}
					>
						<CloseIcon />
					</button>
					<p className='text-2xl text-blue-500 font-bold mb-2'>
						{t("deviceReassign")}
					</p>
				</div>
				<div className='flex-1 overflow-y-auto'>
					<Reassign keepValue={true} />
				</div>
			</div>
		</div>
	);
};

export default ReassignModal;
