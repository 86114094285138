import { useEffect, useRef, useState } from "react";
import Header from "../layout/Header";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import CustomerSelect from "../selects/CustomerSelect";
import LocationSelect from "../selects/LocationSelect";
import DeviceSelect from "../selects/DeviceSelect";
import Alert from "../layout/Alert";
import { RefreshIcon } from "../Icons";
import ChannelReassign from "./ChannelReassign";
import InstalationModal from "../instalation/InstalationModal";
import { useTranslation } from "react-i18next";

const Reassign = ({keepValue = false}) => {
	const { t, i18n } = useTranslation();
	const axiosPrivate = useAxiosPrivate();

	const [savingForm, setSavingForm] = useState(false);

	const [selectedCustomer, setSelectedCustomer] = useState(-1);
	const [selectedLegacyDevice, setSelectedLegacyDevice] = useState(-1);
	const [selectedNewDevice, setSelectedNewDevice] = useState(-1);
	const [selectedLocation, setSelectedLocation] = useState(-1);

	const [isInitModalOpen, setIsInitModalOpen] = useState(false);
	const [newDeviceData, setNewDeviceData] = useState([]);
	const [deviceLines, setDeviceLines] = useState([]);
	const [hasPhotos, setHasPhotos] = useState();

	const [sameConn, setSameConn] = useState(false);
	const [channels, setChannels] = useState([]);
	const [metrics, setMetrics] = useState([]);

	const [deviceLinesData, setDeviceLinesData] = useState({});

	const [alertMessage, setAlertMessage] = useState("");
	const [alertType, setAlertType] = useState("success");
	const [showAlert, setShowAlert] = useState(false);

	const handleSetAlert = (message, type) => {
		setAlertMessage(message);
		setAlertType(type);
		setShowAlert(true);
		setTimeout(() => {
			setShowAlert(false);
		}, 2000);
	};

	const handleReassign = async () => {
		if (
			selectedLegacyDevice > 0 &&
			selectedNewDevice > 0 &&
			selectedLocation > 0
		) {
			const formData = new FormData();
			formData.append("customer", selectedCustomer);
			formData.append("legacyDevice", selectedLegacyDevice);
			formData.append("newDevice", selectedNewDevice);
			formData.append("newLocation", selectedLocation);
			formData.append("deviceLinesData", JSON.stringify(deviceLinesData));

			setSavingForm(true);
			try {
				const response = await axiosPrivate.post(
					"reassignDevice",
					formData
				);

				if (response.status === 200) {
					handleSetAlert(t("alertSuccessReassign"), "success");
					// here get data for the new device
					if (
						response.data.data.device_data &&
						response.data.data.device_data.length > 0
					) {
						setNewDeviceData(response.data.data.device_data[0]);
					}
				} else {
					handleSetAlert(t("alertErrorReassign"), "error");
					throw new Error(`HTTP error! Status: ${response.status}`);
				}
			} catch (error) {
				console.error("Error:", error);
				handleSetAlert(t("alertErrorReassign"), "error");
			}
			setSavingForm(false);
			setSelectedCustomer(-1);
			setSameConn(false);
		} else {
			handleSetAlert(t("selectAllData"), "error");
		}
	};

	const handleDevicesCompare = async () => {
		try {
			const response = await axiosPrivate.post(
				`getDevicesCompare/${selectedCustomer}/${selectedLegacyDevice}/${selectedNewDevice}`
			);
			const data = response.data.data;
			let con1 = data.connectors[0].connector_id ?? 1;
			let con2 = data.connectors[1].connector_id ?? 2;
			if (con1 === con2) {
				setSameConn(true);
			} else {
				setSameConn(false);
				setChannels(data.channels);
				setMetrics(data.metrics);
			}
		} catch (error) {
			console.error("Error:", error);
		}
	};

	const handleSetDeviceLinesData = (id, data) => {
		setDeviceLinesData((previousData) => {
			return {
				...previousData,
				[id]: data,
			};
		});
	};

	const handleGetDeviceLines = async (dev) => {
		try {
			const response = await axiosPrivate.post(
				`deviceDetail/` + dev.vhost_id + `/` + dev.id
			);
			const data = response.data.data;
			setDeviceLines(data);
			setIsInitModalOpen(true);
		} catch (error) {
			console.error("Error:", error);
		}
	};

	useEffect(() => {
		if (newDeviceData.device_code) {
			handleGetDeviceLines(newDeviceData);
			setHasPhotos(
				newDeviceData.has_image === "0"
					? 0
					: newDeviceData.has_image === "1" ||
					  newDeviceData.has_image !== newDeviceData.dev_lines
					? 1
					: 2
			);
		}
	}, [newDeviceData]);

	useEffect(() => {
		const disableScroll = () => {
			document.body.classList.add("overflow-hidden");
		};
		const enableScroll = () => {
			document.body.classList.remove("overflow-hidden");
		};

		if (isInitModalOpen) {
			disableScroll();
		} else {
			enableScroll();
		}

		return () => {
			enableScroll(); // Enable scroll when the component is unmounted
		};
	}, [isInitModalOpen]);

	useEffect(() => {
		setChannels([]);
		setMetrics([]);
		setDeviceLinesData({});
		if (selectedLegacyDevice > 0 && selectedNewDevice > 0) {
			handleDevicesCompare();
		}
	}, [selectedLegacyDevice, selectedNewDevice]);

	useEffect(() => {
		setSelectedLegacyDevice(-1);
		setSelectedNewDevice(-1);
		setChannels([]);
		setMetrics([]);
		setDeviceLinesData({});
	}, [selectedCustomer]);

	return (
		<div>
			<div className='mx-3 mt-5'>
				<CustomerSelect
					onSelect={(cust) => setSelectedCustomer(cust)}
					type='Reass'
					keepValue={keepValue}
					required={true}
					clearSelection={selectedCustomer == -1}
				/>
				<DeviceSelect
					selectedCustomer={selectedCustomer}
					onSelect={(dev) => setSelectedLegacyDevice(dev)}
					type='Reass'
					label='legacyDevice'
					keepValue={keepValue}
					required={true}
				/>
				<DeviceSelect
					selectedCustomer={selectedCustomer}
					onSelect={(dev) => setSelectedNewDevice(dev)}
					type='Reass'
					label='newDevice'
					keepValue={keepValue}
					required={true}
				/>
				<LocationSelect
					selectedCustomer={selectedCustomer}
					onSelect={(loc) => setSelectedLocation(loc)}
					type='Reass'
					keepValue={keepValue}
					required={true}
				/>
				<button
					onClick={() => handleReassign()}
					type='button'
					className='mb-3 bg-white border border-blue-500 hover:bg-blue-100 text-blue-500 hover:text-blue-700 text-center font-bold py-2 px-4 rounded'
				>
					{!savingForm && t("reassign")}
					{savingForm && <RefreshIcon isRefresh={savingForm} />}
				</button>
				{metrics && metrics.length > 0
					? metrics.map((metric) => (
							<ChannelReassign
								key={metric.device_line_id}
								metric={metric}
								channels={channels}
								setLines={(data) =>
									handleSetDeviceLinesData(
										metric.device_line_id,
										data
									)
								}
							/>
					  ))
					: selectedCustomer > 0 &&
					  selectedLegacyDevice > 0 &&
					  selectedNewDevice > 0 &&
					  !sameConn && (
							<p className='text-center mt-2'>
								{t("noDeviceLines")}
							</p>
					  )}
				{sameConn && (
					<p className='text-center mt-2 text-success'>
						{t("sameConnectors")}
					</p>
				)}
			</div>
			{showAlert && <Alert message={alertMessage} type={alertType} />}

			{isInitModalOpen && (
				<div>
					<InstalationModal
						dev={newDeviceData}
						lines={deviceLines}
						onClose={() => setIsInitModalOpen(false)}
						hasPhotos={hasPhotos}
						setHasPhotos={(val) => setHasPhotos(val)}
					/>
				</div>
			)}
		</div>
	);
};
export default Reassign;
