import { useEffect, useState } from "react";
import Select from "react-select";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useTranslation } from "react-i18next";

const DeviceSelect = ({
	selectedCustomer,
	onSelect,
	type = "",
	label = "device",
	keepValue = true,
	required = false,
	labelClass = "",
	useCodeAsValue = false,
}) => {
	const axiosPrivate = useAxiosPrivate();

	const { t } = useTranslation();

	const [devices, setDevices] = useState([]);
	const [selectedDevice, setSelectedDevice] = useState(
		keepValue ? localStorage.getItem(label + type) ?? -1 : -1
	);
	const [selectedDeviceName, setSelectedDeviceName] = useState("");

	const handleSelectedDevice = (selectedOption) => {
		if (keepValue) {
			localStorage.setItem(label + type, selectedOption.value);
			localStorage.setItem(label + "Name" + type, selectedOption.label);
		}
		setSelectedDevice(selectedOption.value);
		setSelectedDeviceName(selectedOption.label);
	};

	useEffect(() => {
		setDevices([]);
		if (!keepValue) {
			setSelectedDevice(-1);
			onSelect(-1);
		}
		const handleGetDevices = async () => {
			try {
				if (selectedCustomer > 0) {
					const response = await axiosPrivate.post(
						`getCustomerDevices/${selectedCustomer}`
					);
					const data = response.data.data;
					setDevices(data);
				}
			} catch (error) {
				console.error("Error:", error);
			}
		};
		if (selectedCustomer != -1) {
			handleGetDevices();
		}
	}, [selectedCustomer, axiosPrivate]);

	useEffect(() => {
		onSelect(selectedDevice);
	}, [selectedDevice]);

	// Convert devices data to react-select options format
	const selectOptions = devices.map((dev) => ({
		value: useCodeAsValue ? dev.device_id : dev.vhost_device_id,
		label: dev.device_name,
	}));
	return (
		<div className='shadow-md'>
			<label className={labelClass}>
				{t(label)} {required && <span className='text-red-600'>*</span>}
			</label>
			{selectOptions.length > 0 ? (
				<Select
					options={selectOptions}
					value={
						selectOptions.find(
							(option) => option.value === selectedDevice
						) || -1
					}
					onChange={handleSelectedDevice}
					isSearchable
					placeholder={t("select")}
					className='mb-3'
				/>
			) : (
				<Select placeholder={t("select")} className='mb-3' value={-1} />
			)}
		</div>
	);
};

export default DeviceSelect;
