import { useEffect, useState } from "react";
import ArticleModal from "./ArticleModal";

const Article = ({ article, articles, type, articleModal = false }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [tagsWithHashtags, setTagsWithHashtags] = useState("");

	const handleModal = (state) => {
		setIsModalOpen(state);
	};

	const tags = article?.tags;
	useEffect(() => {
		if (tags) {
			setTagsWithHashtags(() => {
				return tags
					.split(",")
					.map((tag) => `#${tag.trim()}`)
					.join(" ");
			});
		}
	}, [tags]);

	useEffect(() => {
		const disableScroll = () => {
			document.body.classList.add("overflow-hidden");
		};
		const enableScroll = () => {
			document.body.classList.remove("overflow-hidden");
		};

		if (isModalOpen || articleModal) {
			disableScroll();
		} else {
			enableScroll();
		}

		return () => {
			enableScroll(); // Enable scroll when the component is unmounted
		};
	}, [isModalOpen]);

	const truncatePerex = (perex) => {
		const maxLength = 100;
		if (perex.length > maxLength) {
			return perex.substring(0, maxLength) + " ...";
		}
		return perex;
	};

	return (
		<div>
			<div
				onClick={() => handleModal(true)}
				className='p-3 rounded-lg bg-white-200 border border-blue-500 rounded-full mb-3 shadow-md'
			>
				<div className=''>
					<div className='text-xl font-medium text-blue-500 w-full'>
						{article.title}
					</div>
					<div className='text-xs font-medium w-full'>
						<span className='mr-2'>{type}</span>
						<span className='text-gray-500'>
							{tagsWithHashtags}
						</span>
					</div>
					<div className='flex flex-col gap-2'>
						<div className='text-gray-500'>
							{truncatePerex(article.perex)}
						</div>
					</div>
				</div>
			</div>
			{isModalOpen && (
				<ArticleModal
					article={article}
					articles={articles}
					onClose={() => handleModal(false)}
				/>
			)}
		</div>
	);
};

export default Article;
